import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@envconfig';
import { ApiService } from '@project/services/api.service';
// import * as moment from 'moment';

@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.scss']
})
export class ProductListingComponent implements OnInit {
  hide: boolean;
  tableData: any[];
  pageData: { perPage: number; currentPage: number; totalItems: number };
  sortBy: string;
  sort_order: string;
  keyword: string;
  searchBy: string;
  userForm: FormGroup;
  imageUrl: string;
  p: number = 1;

  constructor(
    private api: ApiService,
    private router: Router
  ) {
    this.hide = true;
    this.tableData = [];
    this.pageData = {
      perPage: 10,
      currentPage: 1,
      totalItems: 0
    };
    this.imageUrl = environment.IMAGE_BASE_URL;
  }
  ngOnInit(): void {
    this.getPrductList(this.pageData.currentPage);
  }

  public getPrductList(pageNo: number): void {
    this.pageData.currentPage = pageNo;
    const dataSet = {
      currentPage: this.pageData.currentPage,
      perPage: this.pageData.perPage,
    };
    this.api.post(`/vendorportal/product/list`, {}).subscribe((res: any) => {
      if (res.status === 200) {
        this.tableData = res.data;
        // this.pageData.totalItems = res.meta.total;
      } else {
        this.api.alert(res.message, 'warning');
      }
    }, (err: any) => {
      this.api.alert(err.message ? err.message : 'Something went wrong.', 'error');
    });
  }

  deleteProduct(id: any) {
    this.api.alertModal('Are you sure ?', 'You will not be able to restore the product once it is deleted!', 'warning', true, true, 'Delete Product').then((result: any) => {
      if (result.isConfirmed) {
        this.api.get(`/vendorportal/product/delete/${id}`).subscribe(res => {
          this.api.alert(res.message, 'success');
          this.getPrductList(this.pageData.currentPage);
        }, err => {
          this.api.alert(err.message, 'success');
        });
      }
    });
  }

  selectFeatured(event: any, id: any) {
    this.api.get(`/vendorportal/product/featured-status-change/${id}`).subscribe((res: any) => {
      if (res.status === 200) {
        this.api.alert(res.message, 'success');
        this.getPrductList(this.pageData.currentPage);
      }
    });
  }

}
