<div class="loginWrap">
  <div class="mClose text-right">
    <button class="btn shadow-none p-1 bg-transparent text-dark " mat-dialog-close>
      <i class="material-icons m-0 align-middle">close</i>
    </button>
  </div>
  <div class="registerModalInner">
    <div class="h1 text-center">Sign Up Your Seller Account</div>



    <mat-horizontal-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="primaryInfo">
        <form [formGroup]="primaryInfo">
          <ng-container>
            <div class="h5 text-uppercase mb-5">Step 1: Primary Information</div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100">
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="Name" formControlName="name">
                  <mat-error
                    *ngIf="primaryInfo.controls.name.touched && primaryInfo.controls.name.hasError('required')">
                    This is required
                  </mat-error>
                  <mat-error *ngIf="primaryInfo.controls.name.touched && primaryInfo.controls.name.hasError('pattern')">
                    Please enter a valid name
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="Email" formControlName="email">
                  <mat-error
                    *ngIf="primaryInfo.controls.email.touched && primaryInfo.controls.email.hasError('required')">
                    This is required
                  </mat-error>
                  <mat-error
                    *ngIf="primaryInfo.controls.email.touched && primaryInfo.controls.email.hasError('pattern')">
                    Please enter a valid email
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100" (click)="onCountryClicked()">
                  <mat-label>Country</mat-label>
                  <mat-select formControlName="country" (selectionChange)="onCountryChange($event)">
                    <mat-option *ngFor="let country of countries" [value]="country._id" [id]="country.countryCode">
                      {{country.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="primaryInfo.controls.country.touched && primaryInfo.controls.country.hasError('required')">
                    This is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100" *ngIf="countryCode">
                  <mat-label>Phone Number</mat-label>
                      <ngx-mat-intl-tel-input [preferredCountries]="[countryCode]" [enablePlaceholder]="true" [enableSearch]="true" name="mobile" inputPlaceholder="Mobile Number" 
                    formControlName="mobile" style="color: white;"></ngx-mat-intl-tel-input>
                    <mat-error
                      *ngIf="primaryInfo.controls.mobile.touched && primaryInfo.controls.mobile.hasError('required')">
                      This is required
                    </mat-error>
                    <mat-error *ngIf="primaryInfo.controls['mobile']?.errors?.validatePhoneNumber">Invalid Number
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="legacy" class="w-100" *ngIf="!countryCode">
                  <mat-label>Phone Number</mat-label>
                      <ngx-mat-intl-tel-input [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="true" name="mobile" inputPlaceholder="Mobile Number" 
                    formControlName="mobile" style="color: white;"></ngx-mat-intl-tel-input>
                    <mat-error
                      *ngIf="primaryInfo.controls.mobile.touched && primaryInfo.controls.mobile.hasError('required')">
                      This is required
                    </mat-error>
                    <mat-error *ngIf="primaryInfo.controls['mobile']?.errors?.validatePhoneNumber">Invalid Number
                    </mat-error>
                </mat-form-field>
              </div>
              <!-- <div class="col-md-6">
                <mat-form-field appearance="legacy" class="w-100">
                  <mat-label>Otp Type</mat-label>
                  <mat-select formControlName="otpType">
                    <mat-option value="mobile">
                      Mobile
                    </mat-option>
                    <mat-option value="email">
                      Email
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="primaryInfo.controls.otpType.touched && primaryInfo.controls.otpType.hasError('required')">
                    This is required
                  </mat-error>
                </mat-form-field>
              </div> -->
            </div>
            <div class="w-100 d-sm-flex justify-content-between align-items-center bottomBtns">
              <p class="mb-0"> Already have an account? <a class="text-uppercase font-weight-bold test-dark"
                  (click)="onClickOpenLogin()">Login</a> </p>
              <!-- <button class="btn btn-deep ml-4" mat-button matStepperNext
                (click)="submit(primaryInfo.value, 'primaryInfo')">NEXT</button> -->
              <button class="btn btn-deep ml-4" (click)="goForward(stepper,'primaryInfo')">NEXT
              </button>
            </div>
          </ng-container>
          <!-- <button (click)="goBackCard(steppercard)" [disabled]="steppercard?.selectedIndex === 0"
          class=" btn btn-gra1">Back
        </button>
        <button class="btn btn-gra1" (click)="goForwardCard(steppercard,steppercard?.selectedIndex)"
          *ngIf="steppercard?.selectedIndex!==3">next
        </button> -->
        </form>
      </mat-step>
      <mat-step [stepControl]="securityForm">
        <form [formGroup]="securityForm">
          <div class="h5 text-uppercase mb-5">Step 2 - Security</div>
          <div class="row">
            <div class="col-md-8">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Password</mat-label>
                <input matInput formControlName="password">
                <mat-error
                  *ngIf="securityForm.controls.password.touched && securityForm.controls.password.hasError('required')">
                  This is required
                </mat-error>
                <mat-error
                  *ngIf="securityForm.controls.password.touched && securityForm.controls.password.hasError('minlength')">
                  Password must be at least 6 characters
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <div class="resendOtp">
                <span *ngIf="timeLeft > 0">Resend OTP in {{timeLeft}}s.</span>
                <button (click)="resendOTP()" [disabled]="timeLeft > 0">Resend OTP</button>
              </div>
            </div>
            <!-- *ngIf="otpField==='email'" -->
            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Email verification OTP</mat-label>
                <input matInput formControlName="emailOTP">
                <mat-error
                  *ngIf="securityForm.controls.emailOTP.touched && securityForm.controls.emailOTP.hasError('required')">
                  This is required
                </mat-error>
                <mat-error
                  *ngIf="securityForm.controls.emailOTP.touched && securityForm.controls.emailOTP.hasError('minlength')">
                  OTP should be 4 characters
                </mat-error>
                <mat-error
                  *ngIf="securityForm.controls.emailOTP.touched && securityForm.controls.emailOTP.hasError('maxlength')">
                  OTP should be 4 characters
                </mat-error>
              </mat-form-field>
            </div>
            <!-- *ngIf="otpField==='mobile'" -->
            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Phone number verification OTP</mat-label>
                <input matInput formControlName="mobileOTP">
                <mat-error
                  *ngIf="securityForm.controls.mobileOTP.touched && securityForm.controls.mobileOTP.hasError('required')">
                  This is required
                </mat-error>
                <mat-error
                  *ngIf="securityForm.controls.mobileOTP.touched && securityForm.controls.mobileOTP.hasError('minlength')">
                  OTP should be 4 characters
                </mat-error>
                <mat-error
                  *ngIf="securityForm.controls.mobileOTP.touched && securityForm.controls.mobileOTP.hasError('maxlength')">
                  OTP should be 4 characters
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-between align-items-center bottomBtns">
            <button class="btn btn-outline-deep mr-auto" mat-button matStepperPrevious>BACK</button>
            <!-- <countdown [config]="{leftTime: 30}"></countdown> -->
            <!-- <a class="text-uppercase">Skip for now</a> -->
            <!-- <button class="btn btn-deep ml-4" mat-button matStepperNext
              (click)="submit(securityForm.value, 'securityForm')" [disabled]="securityForm.invalid">NEXT</button> -->
            <button class="btn btn-deep ml-auto" (click)="goForward(stepper,'securityForm')">NEXT
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="businessInfo">
        <form [formGroup]="businessInfo">
          <div class="h5 text-uppercase mb-5">Step 3 - Business Information</div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Business Name</mat-label>
                <input matInput placeholder="Name" formControlName="businessName">
                <mat-error
                  *ngIf="businessInfo.controls.businessName.touched && businessInfo.controls.businessName.hasError('required')">
                  This is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Business Address</mat-label>
                <input matInput placeholder="Business Address" formControlName="businessAddress">
                <mat-error
                  *ngIf="businessInfo.controls.businessAddress.touched && businessInfo.controls.businessAddress.hasError('required')">
                  This is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Business Registration Number</mat-label>
                <input matInput placeholder="Business Registration Number" formControlName="businessRegistrationNumber">
                <mat-error
                  *ngIf="businessInfo.controls.businessRegistrationNumber.touched && businessInfo.controls.businessRegistrationNumber.hasError('required')">
                  This is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Primary Contact Person</mat-label>
                <input matInput placeholder="Primary Contact Person" formControlName="primaryContactPerson">
                <mat-error
                  *ngIf="businessInfo.controls.primaryContactPerson.touched && businessInfo.controls.primaryContactPerson.hasError('required')">
                  This is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Business Type</mat-label>
                <mat-select formControlName="vendorType" multiple>
                  <mat-option *ngFor="let vendor of businesstypes" [value]="vendor._id">
                    {{vendor.title === 'Designer' ? 'Create' : vendor.title}}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="businessInfo.controls.vendorType.touched && businessInfo.controls.vendorType.hasError('required')">
                  This is required
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Public Store Name</mat-label>
                <input matInput placeholder="Name" formControlName="publicStoreName">
                <mat-error
                  *ngIf="businessInfo.controls.publicStoreName.touched && businessInfo.controls.publicStoreName.hasError('required')">
                  This is required
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Upload any Document verifying</mat-label>
                <mat-select formControlName="verificationDocumentType">
                  <mat-option value="nationalIdcard">National Identity Card
                  </mat-option>
                  <mat-option value="drivinglicense">Driving License
                  </mat-option>
                  <mat-option value="tin">TIN</mat-option>
                  <mat-option value="businessPermit">Business Permit
                  </mat-option>
                  <mat-option value="passport">Passport</mat-option>
                  <mat-option value="trulioo">Trulioo</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="businessInfo.controls.verificationDocumentType.touched && businessInfo.controls.verificationDocumentType.hasError('required')">
                  This is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">

              <label class="update-profile-img align-items-center d-flex  border-bottom   py-3 justify-content-between">
                <span *ngIf="!verificationDocumentName">Upload File </span>
                <span *ngIf="verificationDocumentName">{{verificationDocumentName}} </span>
                <i class="material-icons mr-2 mb-0">
                  drive_folder_upload
                </i>
                <input type="file" multiple="false" accept="image/*"
                  (change)="uploadverificationDocument(verificationId)" type="file" #verificationId hidden>
                <!-- <img src="./assets/images/capture.svg" alt="">
                 -->
              </label>
              <mat-error
                *ngIf="businessInfo.controls.verificationDocument.touched && businessInfo.controls.verificationDocument.hasError('required')">
                This is required
              </mat-error>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Upload any ID</mat-label>
                <mat-select formControlName="idDocumentType">
                  <mat-option value="nationalIdcard">National Identity Card
                  </mat-option>
                  <mat-option value="drivinglicense">Driving License
                  </mat-option>
                  <mat-option value="tin">TIN</mat-option>
                  <mat-option value="businessPermit">Business Permit
                  </mat-option>
                  <mat-option value="passport">Passport</mat-option>
                  <mat-option value="trulioo">Trulioo</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="businessInfo.controls.idDocumentType.touched && businessInfo.controls.idDocumentType.hasError('required')">
                  This is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <label class="update-profile-img align-items-center d-flex  border-bottom   py-3 justify-content-between">
                <span *ngIf="!documentIdName">Upload File </span>
                <span *ngIf="documentIdName">{{documentIdName}} </span>
                <i class="material-icons mr-2 mb-0">
                  drive_folder_upload
                </i>
                <input type="file" multiple="false" accept="image/*" (change)="uploadIdDocument(documentId)" type="file"
                  #documentId hidden>
              </label>
              <mat-error
                *ngIf="businessInfo.controls.idDocument.touched && businessInfo.controls.idDocument.hasError('required')">
                This is required
              </mat-error>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-between align-items-center bottomBtns">
            <button class="btn btn-outline-deep mr-auto" matStepperPrevious>BACK</button>
            <!-- <button class="text-uppercase" mat-button matStepperNext (click)="submit('businessInfo');">Skip for
              now</button> -->
            <!-- <button class="btn btn-deep ml-4" mat-button matStepperNext >NEXT</button> -->
            <!-- <button class="btn btn-deep ml-4" (click)="gotoBusinessStep(stepper)">Next
            </button> -->
            <button class="btn btn-deep ml-4" (click)="submit('businessInfo');">Sign Up</button>

          </div>
        </form>
      </mat-step>
      <!-- <mat-step [stepControl]="billingInfo">
        <form [formGroup]="billingInfo">
          <ng-container>
            <div class="h5 text-uppercase mb-5">Step 4 - Billing Information</div>

            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="legacy" class="w-100">
                  <mat-label>Credit Card Number</mat-label>
                  <input matInput formControlName="cardNumber">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="legacy" class="w-100">
                  <mat-label>Expiry Date</mat-label>
                  <input matInput formControlName="expMonth">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="legacy" class="w-100">
                  <mat-label>Expiry Year</mat-label>
                  <input matInput formControlName="expYear">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="legacy" class="w-100">
                  <mat-label>CVV</mat-label>
                  <input matInput formControlName="cvv">
                </mat-form-field>
              </div>
              <div class="col-md-12 mb-4">
                <mat-form-field appearance="legacy" class="w-100">
                  <mat-label>Billing Address</mat-label>
                  <input matInput formControlName="billAddress">
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <div class="w-100 d-flex justify-content-between align-items-center bottomBtns">
            <button class="btn btn-outline-deep mr-auto" mat-button matStepperPrevious>BACK</button>
            <button class="btn btn-deep ml-4" (click)="submit('billingInfo');">Sign Up</button>
          </div>
        </form>
      </mat-step> -->
    </mat-horizontal-stepper>

    <!-- <div class="w-100 d-flex justify-content-between align-items-center bottomBtns">
      <button class="btn btn-outline-deep mr-auto">BACK</button>
      <a class="text-uppercase">Skip for now</a>
      <button class="btn btn-deep ml-4">FINISH</button>

    </div> -->


  </div>
</div>