import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@envconfig';
import { ApiService } from '@project/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-listing',
  templateUrl: './order-listing.component.html',
  styleUrls: ['./order-listing.component.scss']
})
export class OrderListingComponent implements OnInit {
  tableData: any[];
  pageData: { perPage: number; currentPage: number; totalItems: number };
  imageUrl: string;
  statusList = [{ name: 'Accept', value: 'accept' }, { name: 'Reject', value: 'reject' }];
  status: string;
  statusFm: FormGroup;

  constructor(
    private api: ApiService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.tableData = [];
    this.pageData = {
      perPage: 5,
      currentPage: 1,
      totalItems: 0
    };
    this.imageUrl = environment.IMAGE_BASE_URL;
  }
  ngOnInit(): void {
    this.getorderList(this.pageData.currentPage);
    this.statusFm = this.fb.group({
      status: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  public changeStatus(id: string, index: number): void {


    if (this.statusFm.value.status) {
      Swal.fire({
        title: 'Are you sure you want to change status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, change it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.api.post(`/vendorportal/order/accept-reject`, { orderId: id, acceptStatus: this.statusFm.value.status }).subscribe((res: any) => {

            if (res.status === 200) {
              this.api.alert(res.message, 'success');
              this.tableData[index].status = res.data.acceptStatus;
            } else {
              this.api.alert(res.message, 'warning');
            }
          }, (err: any) => {
            this.api.alert(err.message ? err.message : 'Something went wrong.', 'error');
          });
        }
      });
    }

  }
  public getorderList(pageNo: number): void {
    this.pageData.currentPage = pageNo;
    const dataSet = {
      currentPage: this.pageData.currentPage,
      perPage: this.pageData.perPage,
    };
    this.api.post(`/vendorportal/order/list`, dataSet).subscribe((res: any) => {
      if (res.status === 200) {
        this.tableData = res.data;
        this.pageData.totalItems = res.totalRecords;
        this.tableData.forEach(element => {
          this.statusFm.patchValue({
            status: element.acceptStatus
          });
        });
      } else {
        this.api.alert(res.message, 'warning');
      }
    }, (err: any) => {
      this.api.alert(err.message ? err.message : 'Something went wrong.', 'error');
    });
  }

}
