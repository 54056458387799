<div class="headerVendor">
    <h1>Order Listing</h1>
    <div class="">
        <app-vendor-header></app-vendor-header>
    </div>
</div>


<div class="order-Head">
    <div class="inner">
        <h5 class="">Order Listing</h5>
        <p class="">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    </div>
</div>

<div class="orderTable">
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <!-- <th class="text-nowrap">Order Id ID</th> -->
                    <th class="text-nowrap">Customer Name </th>
                    <th class="text-nowrap">Purchase Date </th>
                    <th class="text-nowrap">Grand Total </th>
                    <!-- <th class="text-nowrap">Supplier Name </th> -->
                    <th class="text-nowrap">Email</th>
                    <th class="text-nowrap">Status </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="tableData.length > 0">
                    <tr
                        *ngFor="let item of tableData | paginate: { id: 'server', itemsPerPage: pageData?.perPage, currentPage: pageData?.currentPage, totalItems: pageData?.totalItems }; let i = index;">

                        <!-- <td>1</td> -->
                        <td><a [routerLink]="['/vendor/order-details', item?._id]">{{item?.customerDetails?.name}}</a></td>
                        <td><a [routerLink]="['/vendor/order-details', item?._id]">{{item?.orderDate | date}}</a></td>
                        <td><a [routerLink]="['/vendor/order-details', item?._id]">USD {{item?.totalAmount}}</a></td>
                        <!-- <td>{{item?.vendorDetails?.name}}</td> -->
                        <td><a [routerLink]="['/vendor/order-details', item?._id]">{{item?.customerDetails?.email}}</a></td>
                        <td [formGroup]="statusFm">
                            <select (change)="changeStatus(item?._id, i)" formControlName="status" class="form-control">
                                <option value="">Select</option>
                                <option [value]="status?.value" *ngFor="let status of statusList">{{status?.name}}
                                </option>
                            </select>

                        </td>
                    </tr>

                </ng-container>
            </tbody>
        </table>
        <ng-container *ngIf="tableData.length > 0">
            <pagination-controls (pageChange)="getorderList($event)" id="server"></pagination-controls>
        </ng-container>
    </div>
</div>