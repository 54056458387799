import { ShopDropLandingComponent } from './shop-drop-landing/shop-drop-landing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { ForgotDialogStep2Component } from './site/auth/forgot-dialog-step2/forgot-dialog-step2.component';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./site/site.module').then(m => m.SiteModule)
  // },
  {
    path: 'vendor-home',
    component: ShopDropLandingComponent
  },
  {
    path: 'reset-password/:id',
    component: ForgotDialogStep2Component
  },
  {
    path: 'vendor',
    loadChildren: () => import('./site/vendor/vendor.module').then(m => m.VendorModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/vendor-home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
