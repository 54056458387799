<div class="headerVendor">
    <h1>Order Details</h1>
    <app-vendor-header></app-vendor-header>
</div>

<div class="addProduct">
    <div class="orderDetailTable">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Order Number</th>
                <th>Transaction Id</th>
                <th>Order Date</th>
                <!-- <th>Currency</th> -->
                <th>Currency</th>
                <th>Total Amount</th>
                <th>Discount</th>
                <th>Amount Paid</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{detailsData?.orderNumber}}</td>
                <td>{{detailsData?.transactionId}}</td>
                <td>{{detailsData?.orderDate | date}}</td>
                <!-- <td>{{detailsData?.currency}}</td> -->
                <td>USD</td>
                <td>{{(detailsData?.totalAmount)}}</td>
                <td>{{(detailsData?.discount)}}</td>
                <td>{{(detailsData?.amountPaid)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <div class="addSec">
        <div class="row">
          <div class="col-lg-6">
  
            <div class="orderDetailTable proDetailTable">
              <h2>Order items</h2>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of detailsData?.orderedItems">
                      <td>
                        <img src="{{baseImgUrl}}/product/{{item?.productDetails?.image}}" alt="" />
                      </td>
  
                      <td>{{item?.title}}</td>
                      <td>{{item?.quantity}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
  
            <div class="orderDetailTable proDetailTable">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Dimension Title</th>
                      <th>Dimension Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of detailsData?.customDimensions">
                      <td>{{item?.dimension?.title}}</td>
                      <td>{{checkPrice(item?.size)}} {{detailsData?.dimensionLengthType}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-6">
                <div class="itemAdd">
                  <h4>Billing Details</h4>
                  <p><span>Name:</span> {{detailsData?.billingDetails?.name}}
                  </p>
                  <p><span>address:</span> {{detailsData?.billingDetails?.address}}</p>
                  <p><span>contact:</span> {{detailsData?.billingDetails?.address}}</p>
                  <p><span>country:</span> {{getCountryNameById(detailsData?.billingDetails?.country)}}</p>
                  <p><span>email:</span> {{detailsData?.billingDetails?.email}}</p>
                  <p><span>postalCode:</span> {{detailsData?.billingDetails?.postalCode}}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="itemAdd">
                  <h4>Shipping Details</h4>
                  <p><span>Name:</span> {{detailsData?.shippingDetails?.firstName}}
                    {{detailsData?.shippingDetails?.lastName}}
                  </p>
                  <p><span>address:</span> {{detailsData?.shippingDetails?.address}}</p>
                  <p><span>contact:</span> {{detailsData?.shippingDetails?.address}}</p>
                  <p><span>country:</span> {{getCountryNameById(detailsData?.shippingDetails?.country)}}</p>
                  <p><span>email:</span> {{detailsData?.shippingDetails?.email}}</p>
                  <p><span>postalCode:</span> {{detailsData?.shippingDetails?.postalCode}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div>
  
</div>