// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // BASE_API_URL: 'https://ce85-103-225-176-42.ngrok.io',
  // BASE_API_URL: 'https://shopdrop.dedicateddevelopers.us',
  // BASE_API_URL: 'https://admin-shopdrop.dedicatedresource.net',
  // BASE_API_URL: 'https://shopdropnew-admin.dedicateddevelopers.us',
  BASE_API_URL: 'https://shopdropnew-admin.dedicatedresource.net',
  IMAGE_BASE_URL: 'https://shopdropcdn.dedicatedresource.net',
  // IMAGE_BASE_URL: 'https://shopdrop.s3.af-south-1.amazonaws.com',
  // IMAGE_BASE_URL: 'https://d2fnmg1h87nxsl.cloudfront.net',

  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
