import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { Editor } from 'ngx-editor';
import * as _ from 'underscore';
import { environment } from '@envconfig';
import { StorageService } from '@project/services/storage.service';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';
import { NgxImageCompressService } from 'ngx-image-compress';
import { fabric } from 'fabric';
import patternJson from '../../../../assets/csv/pattern.json';
import { MatDialog } from '@angular/material/dialog';
import { EditHelpComponent } from '../add-product/edit-help/edit-help.component';
import { MatSelect } from '@angular/material/select';


@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})

export class EditProductComponent implements OnInit {
  productId: any;
  productTypeForm: FormGroup;
  productCategory: FormGroup;
  productInfo: FormGroup;
  productAttributes: FormGroup;
  filtersAttributes: FormGroup;
  fabricOrientation: FormGroup;
  productPricing: FormGroup;
  // fabricRequired: FormGroup;
  imageForm: FormGroup;
  addFabrics: FormGroup;
  customDimension: FormGroup;
  reviewForm: FormGroup;
  materials: FormArray;
  // materialsR: FormArray;

  countries: any;
  businesstypes: any;
  productTypeList: any;
  subProdTypeList: any;
  productCategoryList: any;
  subCategoryList: any;
  fabricList: any;
  finalCombArr: any;
  finalAttributeCombData: any;
  productDimensionTypeList: any;
  attributes: any;
  productDetail: any;
  isShowVariantTable: any;
  variationImages: any;
  garmentTypes: any;
  plusPrice: any;
  twoFabric: any;
  threeFabric: any;
  fourFabric: any;
  fiveFabric: any;
  sixFabric: any;
  cyodSizePrice: any;
  longDressPrice: any;
  plusSizePrice: any;
  plusSizeValue: any = '16/20/48';
  priceCalculateOn: any;

  files: any = [];
  attributeValueData: any = [];
  filterValueData: any = [];
  arrayFinalResult: any = [];
  fabricCategory: any = [];
  imageForCYOD: any = [];
  imageForCYODFinal: any = [];
  cyodImages: any = [];
  lengthSleeve: any = [];
  allFabricProvider: any = [];
  finalFilterValueData: any = [];

  step3VisiblePart: boolean;
  stepSixAndFiveFields: boolean;
  step3TwoFields: boolean;
  stepFabricShowField: boolean;

  editor: Editor;
  editorF: Editor;

  fabricPriceValue: number = 0;
  selectedCYOD: number = 0;
  selectedPlus: number = 0;
  selectedLongDress: number = 0;
  selectedIndex: number = 0;
  topPosition: number;

  imageChangedEvent: any = '';
  imageSrc: any = '';
  dimnSwitch: string = 'inputs';
  imageUrl = environment.IMAGE_BASE_URL;
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  plusSizeChart: any = [
    '1/4/32', '2/6/34', '4/8/36', '6/10/38', '8/12/40', '10/14/42', '12/16/44', '14/18/46', '16/20/48', '18/22/50', '20/24/52', '22/26/54', '24/28/56'
  ]
  customDimensions: any = [];
  calfLengthThreeFour: any;
  calfLengthFull: any;
  ankleLengthFull: any;
  ankleLengthThreeFour: any;
  selectedCustomDimensions: any = [];
  fabricVendorList: any;
  vendorFabricSelection: any = [];
  customDimensionImage: any;
  fabricTypeCategoryList: any = [];
  fabricTypeSubCategoryList: any;
  isEditing: boolean = false;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  fabricTypeCategory: any;
  fabricTypeSubCategory: any;
  allCyodData: any;
  isSave: boolean = true;
  updatedPattern: any;
  isOrientation: string;

  // To Check form unsaved or not
  isDirty = false;
  garmentLists: any = [];
  p: number = 1;
  lengthType: string = 'inch';
  customDimensionsComment: any = [];

  miniId: any
  totalprice: any;
  patternData: any;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public router: Router,
    public activateRoute: ActivatedRoute,
    public storage: StorageService,
    private imageCompress: NgxImageCompressService,
    public dialog: MatDialog
  ) {
    this.editor = new Editor();
    this.editorF = new Editor();
    this.formsInit();
    this.lengthSleeve = [
      { dress: 'Mini', sleeve: 'Sleeveless', price: 0 }, { dress: 'Above Knee ', sleeve: 'Sleeveless', price: 0 }, { dress: 'Knee', sleeve: 'Sleeveless', price: 0 }, { dress: 'Calf', sleeve: 'Sleeveless', price: 0 }, { dress: 'Ankle', sleeve: 'Sleeveless', price: 0 },
      { dress: 'Mini', sleeve: '1/4 Sleeve', price: 0 }, { dress: 'Above Knee ', sleeve: '1/4 Sleeve', price: 0 }, { dress: 'Knee', sleeve: '1/4 Sleeve', price: 0 }, { dress: 'Calf', sleeve: '1/4 Sleeve', price: 0 }, { dress: 'Ankle', sleeve: '1/4 Sleeve', price: 0 },
      { dress: 'Mini', sleeve: 'Elbow', price: 0 }, { dress: 'Above Knee ', sleeve: 'Elbow', price: 0 }, { dress: 'Knee', sleeve: 'Elbow', price: 0 }, { dress: 'Calf', sleeve: 'Elbow', price: 0 }, { dress: 'Ankle', sleeve: 'Elbow', price: 0 },
      { dress: 'Mini', sleeve: '3/4 Sleeve', price: 0 }, { dress: 'Above Knee ', sleeve: '3/4 Sleeve', price: 0 }, { dress: 'Knee', sleeve: '3/4 Sleeve', price: 0 }, { dress: 'Calf', sleeve: '3/4 Sleeve', price: 0 }, { dress: 'Ankle', sleeve: '3/4 Sleeve', price: 0 },
      { dress: 'Mini', sleeve: 'Full', price: 0 }, { dress: 'Above Knee ', sleeve: 'Full', price: 0 }, { dress: 'Knee', sleeve: 'Full', price: 0 }, { dress: 'Calf', sleeve: 'Full', price: 0 }, { dress: 'Ankle', sleeve: 'Full', price: 0 },
    ];
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((res: any) => {
      this.productId = res.id;
    });
    this.step3VisiblePart = false;
    this.getProductType();
    this.getSettingValue();
    this.getGarmentTypes();
    this.getFebric();
    this.getPatternType();
    // this.addMaterials();
    this.getCustomDimen();
    this.getFabricCategory();
    setTimeout(() => { this.stepper._getIndicatorType = () => 'number'; }, 1000);
    // this.productCategory.controls.productSubType.valueChanges.subscribe((val: any) => {
    // const data = { product_type: this.productCategory.controls.productType.value, product_sub_type: val };
    // this.getProductCategory(data);
    // });
    // To Check form unsaved or not
    this.productCategory.valueChanges.subscribe(e => this.isDirty = true);
    this.productInfo.valueChanges.subscribe(e => this.isDirty = true);
    this.productAttributes.valueChanges.subscribe(e => this.isDirty = true);
    this.productPricing.valueChanges.subscribe(e => this.isDirty = true);
    this.imageForm.valueChanges.subscribe(e => this.isDirty = true);
    this.addFabrics.valueChanges.subscribe(e => this.isDirty = true);
    this.customDimension.valueChanges.subscribe(e => this.isDirty = true);
    this.reviewForm.valueChanges.subscribe(e => this.isDirty = true);


  }

  onChange(miniId: any) {
    if (miniId?.target?.value === 'bottom1' || miniId?.target?.value === 'bottom2' || miniId?.target?.value === 'bottom3' || miniId?.target?.value === 'bottom4' || miniId?.target?.value === 'bottom5' || miniId?.target?.value === 'bottom6') {
      this.garmentLists.map((a: any) => {
        if (a.id === 'Collar') {
          const indx = a.data.findIndex((datas: any) => {
            datas.title === 'Collar1'
            // console.log('datas.title', datas.title);
          })
          if (indx === -1) {
            a.data.forEach((ele: any) => {
              if (ele.value == 'collar1') {
                ele.isVisible = true;
              }
            });
          }
        }
      })
    }

    // console.log(this.garmentLists, "+++++++++++");
  }

  // To Check form unsaved or not
  canDeactivate() {
    return this.isDirty;
  }

  loadCanvas(imageForCYODFinal?: any, productDetail?: any) {

    let canvas: any;
    let shape: any;

    let selectedPosition: any = 'top';
    let selectedFabric: any;
    let selectedFabricName: any;
    let fabricItem: any = document.getElementsByClassName('fabricItem');
    let fabricRadio: any = document.getElementsByClassName('fabricRadio');


    let imgWidth: any = document.getElementById('img-width');
    let patternRepeat: any = document.getElementById('pattern-repeat');
    let patternLeft: any = document.getElementById('patternLeft');
    let patternTop: any = document.getElementById('patternTop');
    let patternRotate: any = document.getElementById('patternRotate');

    let clothTop: any = document.getElementById('clothTop');

    let repeatValue: any = 'repeat';
    let offsetX: number = Number(0);
    let offsetY: number = Number(0);
    let scaleValue: number = Number(1);
    let rotatePattern: number = Number(0);
    let checkPattern: any;
    let sourceFile: any;
    let clothTopPos: number = productDetail.clothTopPos ? Number(productDetail.clothTopPos) : Number(0);
    let clothLeftPos: number = productDetail.clothLeftPos ? Number(productDetail.clothLeftPos) : Number(0);
    sessionStorage.setItem('clothTopPos', JSON.stringify(clothTopPos));
    sessionStorage.setItem('clothLeftPos', JSON.stringify(clothLeftPos));
    // let clothLeftPos: number = Number(0);

    let baseURL: any = this.imageUrl;
    let garmentListArr: any = [];

    // Create Canvas
    canvas = new fabric.Canvas('imgCanvas');
    fabric.Object.prototype.set({
      borderColor: '#aaaaaa',
      cornerColor: '#aaaaaa',
      cornerSize: 12,
      transparentCorners: false
    });


    // Avatar Load
    let svgAvatarImg: any = 'assets/images/avatar.png';
    fabric.Image.fromURL(svgAvatarImg, function (avatar) {
      avatar.selectable = false;
      avatar.scaleToHeight(700);
      canvas.centerObject(avatar);
      canvas.add(avatar);
      canvas.sendToBack(avatar);
    }, { crossOrigin: 'Anonymous' })

    // Load Dress
    let svgChangeClothImg: any = baseURL + '/product/' + productDetail.productSVG;
    fabric.loadSVGFromURL(svgChangeClothImg, function (objects, options) {
      shape = fabric.util.groupSVGElements(objects, options);
      console.log('productDetail', productDetail);
      if (productDetail.patternData && productDetail.patternData.length > 0) {
        // console.log('edit  ==> ( 275 )', productDetail.patternData);
        editedFillCloth();
      } else {
        // console.log('fillCloth ==> ( 279 ) ', selectedFabric);
        fillCloth();
      }

    });

    // Edited Fill Cloth
    function editedFillCloth() {
      imageForCYODFinal.map((x: any) => { if (x.garmentTypeId.includes('618d1a2638d290e7b2dfaedf')) { productDetail.patternData.map((y: any) => { if (y.id === 'mesh') { y.opacity = x.imageOpacity / (x.imageOpacity * 100) } }) } })
      shape._objects.map((x: any) => {
        if (x.path && x.path.length > 0) {
          productDetail.patternData.map((y: any) => {
            if (x.id.includes(y.id.toLowerCase())) {
              sourceFile = y.pattern.source;
              fabric.Image.fromURL(sourceFile, function (oImg: any) {
                x.set('fill', new fabric.Pattern({ source: oImg.getElement(), repeat: y.pattern.repeat, offsetX: y.pattern.offsetX, offsetY: y.pattern.offsetY, patternTransform: [y.pattern.patternTransform[0], y.pattern.patternTransform[1], y.pattern.patternTransform[2], y.pattern.patternTransform[3], y.pattern.patternTransform[4], y.pattern.patternTransform[5]] }));
                x.set('opacity', y.opacity);
                shape._objects.map((obj: any, i: number) => {
                  if (obj.path && obj.path.length > 0) {
                    if (obj.id.toLowerCase().includes('bottom')) { if (obj.id !== productDetail.deafultSvg.bottom) { obj.set('opacity', 0) } else { obj.set('opacity', 1) } };
                    if (obj.id.toLowerCase().includes('sleeves')) { if (obj.id !== productDetail.deafultSvg.sleeve) { obj.set('opacity', 0) } else { obj.set('opacity', 1) } };
                    if (obj.id.toLowerCase().includes('hem')) { if (obj.id !== productDetail.deafultSvg.hem) { obj.set('opacity', 0) } else { obj.set('opacity', 1) } };
                    if (obj.id.toLowerCase().includes('collar')) { if (obj.id !== productDetail.deafultSvg.collar) { obj.set('opacity', 0) } else { obj.set('opacity', 1) } };
                    if (obj.id.toLowerCase().includes('left')) { if (obj.id !== productDetail.deafultSvg.left_sleeve) { obj.set('opacity', 0) } else { obj.set('opacity', 1) } };
                    if (obj.id.toLowerCase().includes('right')) { if (obj.id !== productDetail.deafultSvg.right_sleeve) { obj.set('opacity', 0) } else { obj.set('opacity', 1) } };
                  }
                });

                loadObjects();
              })
            }
          })
        }
      })
    }

    // Fill Cloth
    function fillCloth() {
      shape._objects.map((x: any) => {
        if (x.path && x.path.length > 0) {
          if (selectedFabric && selectedPosition && x.id.includes(selectedPosition.toLowerCase())) {
            fabric.Image.fromURL(selectedFabric, function (oImg: any) {
              x.set('fill', new fabric.Pattern({ source: oImg.getElement(), repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [scaleValue, 0, 0, scaleValue, 0, 0] }));
              x.set('imageName', selectedFabricName);
              loadObjects();
            })
          }
        }
      })
    }

    // Scale Range
    if (imgWidth) {

      imgWidth.oninput = function (e: any) {
        shape._objects.map((x: any) => {
          if (x.path && x.path.length > 0) {
            if (x.id.includes(selectedPosition.toLowerCase())) {
              // x.fill.source.style.transform = `rotate(${Number(e.target.value)}deg)`;
              scaleValue = Number(e.target.value);
              // console.log('scaleValue', scaleValue);
              setTimeout(() => {
                x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [scaleValue, 0, 0, scaleValue, 0, 0] }));
                loadObjects();
                canvas.remove(shape);
              }, 100);
            }
          }
        })
      };
    }

    // Left Offset
    if (patternLeft) {
      patternLeft.oninput = function (e: any) {
        shape._objects.map((x: any) => {
          if (x.path && x.path.length > 0) {
            if (x.id.includes(selectedPosition.toLowerCase())) {
              offsetX = Number(e.target.value);
              setTimeout(() => {
                x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [scaleValue, 0, 0, scaleValue, 0, 0] }));
                loadObjects();
                canvas.remove(shape);
              }, 100);
            }
          }
        })
      }
    }

    // Top Offset
    if (patternTop) {
      patternTop.oninput = function (e: any) {
        shape._objects.map((x: any) => {
          if (x.path && x.path.length > 0) {
            if (x.id.includes(selectedPosition.toLowerCase())) {
              offsetY = Number(e.target.value);
              setTimeout(() => {
                x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [scaleValue, 0, 0, scaleValue, 0, 0] }));
                loadObjects();
                canvas.remove(shape);
              }, 100);
            }
          }
        })
      };
    }

    // Rotate
    if (patternRotate) {
      patternRotate.oninput = function (e: any) {
        shape._objects.map((x: any) => {
          if (x.path && x.path.length > 0) {
            if (x.id.includes(selectedPosition.toLowerCase())) {
              rotatePattern = Number(e.target.value);
              setTimeout(() => {
                if (Number(e.target.value) === 45) {
                  x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [0.707107 * scaleValue, 0.707107 * scaleValue, -0.707107 * scaleValue, 0.707107 * scaleValue, 0, 0] }));
                } else if (Number(e.target.value) === 90) {
                  x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [0, 1 * scaleValue, -1 * scaleValue, 0, 0, 0] }));
                } else if (Number(e.target.value) === 135) {
                  x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [-0.707107 * scaleValue, 0.707107 * scaleValue, -0.707107 * scaleValue, -0.707107 * scaleValue, 0, 0] }));
                } else if (Number(e.target.value) === 180) {
                  x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [-1 * scaleValue, 0, 0, -1 * scaleValue, 0, 0] }));
                } else if (Number(e.target.value) === 225) {
                  x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [-0.707107 * scaleValue, -0.707107 * scaleValue, 0.707107 * scaleValue, -0.707107 * scaleValue, 0, 0] }));
                } else if (Number(e.target.value) === 270) {
                  x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [0, -1 * scaleValue, 1 * scaleValue, 0, 0, 0] }));
                } else if (Number(e.target.value) === 315) {
                  x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [0.707107 * scaleValue, -0.707107 * scaleValue, 0.707107 * scaleValue, 0.707107 * scaleValue, 0, 0] }));
                } else {
                  x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [1 * scaleValue, 0, 0, 1 * scaleValue, 0, 0] }));
                }
                loadObjects();
                canvas.remove(shape);
              }, 100);
            }
          }
        })
      }
    }
    

    // Repeat Selection
    if (patternRepeat) {
      patternRepeat.onchange = function (ev: any) {
        shape._objects.map((x: any) => {
          if (x.path && x.path.length > 0) {
            if (x.id.includes(selectedPosition.toLowerCase())) {
              repeatValue = ev.target.value;
              x.fill.repeat = repeatValue;
              setTimeout(() => {
                x.set('fill', new fabric.Pattern({ source: x.fill.source, repeat: repeatValue, offsetX: offsetX, offsetY: offsetY, patternTransform: [scaleValue, 0, 0, scaleValue, 0, 0] }));
                loadObjects();
                canvas.remove(shape);
              }, 100);
            }
          }
        });
      };
    }

    // Load Canvas Objects
    function loadObjects() {
      shape.scaleToWidth(410);
      shape.selectable = false;
      canvas.centerObject(shape);
      shape.set({ top: clothTopPos });
      if (clothLeftPos > 0) { shape.set({ left: clothLeftPos - 24 }); }
      canvas.add(shape);
      canvas.requestRenderAll();
      // console.log(shape)
      let updatePatternData: any = [];
      // let patternData: any = localStorage.getItem('pattern');
      shape._objects.map((x: any) => {
        if (x.path && x.path.length > 0) {
          patternJson.map((y: any) => {
            // console.log(x.id.toLowerCase() === y.id.toLowerCase());
            if (x.id.toLowerCase() === y.id.toLowerCase()) {
              y.pattern.source = x.fill.source ? x.fill.source.currentSrc : y.pattern.source;
              y.pattern.repeat = repeatValue;
              y.pattern.offsetX = x.fill.offsetX ? x.fill.offsetX : y.pattern.offsetX;
              y.pattern.offsetY = x.fill.offsetY ? x.fill.offsetY : y.pattern.offsetY;
              y.pattern.patternTransform = x.fill.patternTransform ? x.fill.patternTransform : y.pattern.patternTransform;
              y.garmentTypeId = y.garmentTypeId
              y.opacity = x.opacity ? x.opacity : y.opacity;
              updatePatternData.push(y);
            }
          })
        }
      })

      garmentListArr.map((x: any) => {
        // console.log(x, 'x', selectedPosition);
        if (x.value === selectedPosition) {
          x.showGreen = true;
        } else {
          if (x.data.length > 0) {
            x.data.map((y: any) => {
              if (y.value === selectedPosition) {
                y.showGreen = true;
              }
            })
          }
        }
      })
      setTimeout(() => {
        localStorage.setItem('pattern', JSON.stringify(updatePatternData));
      }, 3000);
    }

    // Cloth Top Position Adjust
    if (clothTop) {
      clothTop.onchange = function (e: any) {
        clothTopPos = Number(e.target.value);
        // console.log('clothTopPos ', clothTopPos);
        shape.set({ top: clothTopPos });
        canvas.add(shape);
        sessionStorage.setItem('clothTopPos', JSON.stringify(clothTopPos));
        sessionStorage.setItem('clothLeftPos', JSON.stringify(clothLeftPos));
      }
    }

    // Pattern List
    for (let index = 0; index < fabricItem.length; index++) {
      if (fabricItem[index]) {
        fabricItem[index].addEventListener('click', function (e: any) {
          selectedFabric = e.target.value;
          selectedFabricName = e.target.id;
          var i = new Image();
          i.onload = function () {
            patternLeft.min = '-' + i.width; patternLeft.max = i.width;
            patternTop.min = '-' + i.height; patternTop.max = i.height;
          };
          i.src = selectedFabric;
          fillCloth();
          imgWidth.value = scaleValue;
          patternRepeat.value = repeatValue;
          patternLeft.value = offsetX;
          patternTop.value = offsetY;
          patternRotate.value = rotatePattern;
        })
      }
    }

    // Pattern Position
    setTimeout(() => {
      for (let index = 0; index < fabricRadio.length; index++) {
        if (fabricRadio[index]) {
          fabricRadio[index].addEventListener('click', function (e: any) {
            shape._objects.map((x: any) => {
              if (x.path && x.path.length > 0) {
                // console.log('ID', e.target.id.toLowerCase(), e.target.value);
                // FOR LEFT SLEEVES
                if (e.target.id.toLowerCase() === 'left') {
                  if (x.id.includes('left')) {
                    x.opacity = 0;
                    if (x.id === 'left' + e.target.value.charAt(e.target.value.length - 1)) {
                      x.opacity = 1;
                    }
                  }
                }
                // FOR RIGHT SLEEVES
                if (e.target.id.toLowerCase() === 'right') {
                  if (x.id.includes('right')) {
                    x.opacity = 0;
                    if (x.id === 'right' + e.target.value.charAt(e.target.value.length - 1)) {
                      x.opacity = 1;
                    }
                  }
                }
                // FOR HEMS
                if (e.target.id.toLowerCase() === 'bottom') {
                  if (x.id.includes('wrap')) {
                    x.opacity = 0;
                    if (x.id === 'wrap' + e.target.value.charAt(e.target.value.length - 1)) {
                      x.opacity = 1;
                    }
                  }
                  if (x.id.includes('hem')) {
                    x.opacity = 0;
                    if (x.id === 'hem' + e.target.value.charAt(e.target.value.length - 1)) {
                      x.opacity = 1;
                    }
                  }
                  if (x.id.includes('collar')) {
                    // x.opacity = 0;
                    // if (x.id === 'collar' + e.target.value.charAt(e.target.value.length - 1)) {
                    x.opacity = 1;
                    // }
                  }
                  if (x.id.includes(e.target.id.toLowerCase())) {
                    x.opacity = 0;
                    if (x.id === e.target.value) {
                      x.opacity = 1;
                    }
                  }
                }
              }
            })
            selectedPosition = e.target.value;
            imgWidth.value = scaleValue;
            patternRepeat.value = repeatValue;
            patternLeft.value = offsetX;
            patternTop.value = offsetY;
            patternRotate.value = rotatePattern;
          })
        }
      }
    }, 5000);
    this.garmentLists = [];
    let rightArr: any = [];
    let leftArr: any = [];
    let bottomArr: any = [];
    let collarArr: any = [];
    let hemArr: any = [];
    let tailArr: any = [];
    let wrapArr: any = [];
    setTimeout(() => {
      shape._objects.map((x: any) => {
        if (x.path && x.path.length > 0) {
          if (x.id.includes('right')) {
            if (x.id === 'right5') { rightArr.push({ title: 'Full', value: x.id, isVisible: true }) }
            if (x.id === 'right4') { rightArr.push({ title: '3/4 th', value: x.id, isVisible: true }) }
            if (x.id === 'right3') { rightArr.push({ title: 'Elbow', value: x.id, isVisible: true }) }
            if (x.id === 'right2') { rightArr.push({ title: '1/4 th', value: x.id, isVisible: true }) }
            if (x.id === 'right1') { rightArr.push({ title: 'Cap', value: x.id, isVisible: true }) }
            this.garmentLists.push({ id: 'Right', value: '', data: rightArr })
          }
          if (x.id.includes('left')) {
            if (x.id === 'left5') { leftArr.push({ title: 'Full', value: x.id, isVisible: true }) }
            if (x.id === 'left4') { leftArr.push({ title: '3/4 th', value: x.id, isVisible: true }) }
            if (x.id === 'left3') { leftArr.push({ title: 'Elbow', value: x.id, isVisible: true }) }
            if (x.id === 'left2') { leftArr.push({ title: '1/4 th', value: x.id, isVisible: true }) }
            if (x.id === 'left1') { leftArr.push({ title: 'Cap', value: x.id, isVisible: true }) }
            this.garmentLists.push({ id: 'Left', value: '', data: leftArr })
          }
          if (x.id.includes('tail')) {
            if (x.id === 'tail4') { tailArr.push({ title: 'Tail 4', value: x.id, isVisible: true }) }
            if (x.id === 'tail3') { tailArr.push({ title: 'Tail 3', value: x.id, isVisible: true }) }
            if (x.id === 'tail2') { tailArr.push({ title: 'Tail 2', value: x.id, isVisible: true }) }
            if (x.id === 'tail1') { tailArr.push({ title: 'Tail 1', value: x.id, isVisible: true }) }
            this.garmentLists.push({ id: 'Tail', value: '', data: tailArr })
          }
          if (x.id.includes('bottom')) {
            if (x.id === 'bottom6') { bottomArr.push({ title: 'Full', value: x.id, isVisible: true }) }
            if (x.id === 'bottom5') { bottomArr.push({ title: 'Ankle', value: x.id, isVisible: true }) }
            if (x.id === 'bottom4') { bottomArr.push({ title: 'Mid Calf', value: x.id, isVisible: true }) }
            if (x.id === 'bottom3') { bottomArr.push({ title: 'Knee', value: x.id, isVisible: true }) }
            if (x.id === 'bottom2') { bottomArr.push({ title: 'Above Knee', value: x.id, isVisible: true }) }
            if (x.id === 'bottom1') { bottomArr.push({ title: 'Mini', value: x.id, isVisible: true }) }
            this.garmentLists.push({ id: 'Bottom', value: '', data: bottomArr })
          }
          if (x.id.includes('collar')) {
            if (x.id === 'collar6') { collarArr.push({ title: 'Collar6', value: x.id, isVisible: true }) }
            if (x.id === 'collar5') { collarArr.push({ title: 'Collar5', value: x.id, isVisible: true }) }
            if (x.id === 'collar4') { collarArr.push({ title: 'Collar4', value: x.id, isVisible: true }) }
            if (x.id === 'collar3') { collarArr.push({ title: 'Collar3', value: x.id, isVisible: true }) }
            if (x.id === 'collar2') { collarArr.push({ title: 'Collar2', value: x.id, isVisible: true }) }
            if (x.id === 'collar1') { collarArr.push({ title: 'Collar1', value: x.id, isVisible: false }) }
            this.garmentLists.push({ id: 'Collar', value: '', data: collarArr })
          }
          if (x.id.includes('hem')) {
            if (x.id === 'hem6') { hemArr.push({ title: 'hem6', value: x.id, isVisible: true }) }
            if (x.id === 'hem5') { hemArr.push({ title: 'hem5', value: x.id, isVisible: true }) }
            if (x.id === 'hem4') { hemArr.push({ title: 'hem4', value: x.id, isVisible: true }) }
            if (x.id === 'hem3') { hemArr.push({ title: 'hem3', value: x.id, isVisible: true }) }
            if (x.id === 'hem2') { hemArr.push({ title: 'hem2', value: x.id, isVisible: true }) }
            if (x.id === 'hem1') { hemArr.push({ title: 'hem1', value: x.id, isVisible: true }) }
            this.garmentLists.push({ id: 'hem', value: '', data: hemArr })
          }
          if (x.id.includes('wrap')) {
            if (x.id === 'wrap6') { wrapArr.push({ title: 'Wrap Full', value: x.id, isVisible: true }) }
            if (x.id === 'wrap5') { wrapArr.push({ title: 'Wrap Ankle', value: x.id, isVisible: true }) }
            if (x.id === 'wrap4') { wrapArr.push({ title: 'Wrap Mid Calf', value: x.id, isVisible: true }) }
            if (x.id === 'wrap3') { wrapArr.push({ title: 'Wrap Knee', value: x.id, isVisible: true }) }
            if (x.id === 'wrap2') { wrapArr.push({ title: 'Wrap Above Knee', value: x.id, isVisible: true }) }
            if (x.id === 'wrap1') { wrapArr.push({ title: 'Wrap Mini', value: x.id, isVisible: true }) }
            this.garmentLists.push({ id: 'Wrap', value: '', data: wrapArr })
          }
          if (x.id.includes('top')) { this.garmentLists.push({ id: 'Top', value: x.id, data: [] }) }
          if (x.id.includes('mesh')) { this.garmentLists.push({ id: 'Mesh', value: x.id, data: [] }) }
          if (x.id.includes('pockets')) { this.garmentLists.push({ id: 'Pockets', value: x.id, data: [] }) }
          if (x.id.includes('belt')) { this.garmentLists.push({ id: 'Belt', value: x.id, data: [] }) }
          if (x.id.includes('jacket')) { this.garmentLists.push({ id: 'Jacket', value: x.id, data: [] }) }
          if (x.id.includes('shirt')) { this.garmentLists.push({ id: 'Shirt', value: x.id, data: [] }) }
          if (x.id.includes('upper bust trim')) { this.garmentLists.push({ id: 'Upper Bust Trim', value: x.id, data: [] }) }
          if (x.id.includes('lower bust trim')) { this.garmentLists.push({ id: 'Lower Bust Trim', value: x.id, data: [] }) }
        }
      })
      setTimeout(() => {
        this.garmentLists = _.uniq(this.garmentLists, 'id');
        garmentListArr = this.garmentLists;
      }, 1000);
    }, 3000);
  }

  getProductType() {
    this.apiService.get(`/vendorportal/masterdata/product-types`).subscribe((res: any) => {
      this.productTypeList = res.data;
      this.getDetails();
    });
  }
  getPatternType() {
    this.apiService.get(`/vendorportal/pattern_type/list`).subscribe((res: any) => {
      this.patternData = res.data;
    });
  }
  numberOfFabricsGarment: any = [];
  longDressPricing: any = [];
  getSettingValue() {
    this.apiService.get(`/vendorportal/masterdata/settings`).subscribe((res: any) => {
      res.data.map((x: any) => {
        if (x.slug === "plus-size-pricing") {
          this.plusPrice = x;
        }
        if (x.slug === "two-fabric-garment") {
          x['value'] = 2;
          this.twoFabric = x;
          this.numberOfFabricsGarment.push(this.twoFabric);
        }
        if (x.slug === "three-fabric-garment") {
          x['value'] = 3;
          this.threeFabric = x;
          this.numberOfFabricsGarment.push(this.threeFabric);
        }
        if (x.slug === "four-fabric-garment") {
          x['value'] = 4;
          this.fourFabric = x;
          this.numberOfFabricsGarment.push(this.fourFabric);
        }
        if (x.slug === "five-fabric-garment") {
          x['value'] = 5;
          this.fiveFabric = x;
          this.numberOfFabricsGarment.push(this.fiveFabric);
        }
        if (x.slug === "six-fabric-garment") {
          x['value'] = 6;
          this.sixFabric = x;
          this.numberOfFabricsGarment.push(this.sixFabric);
        }
        if (x.slug === "calf-length-three-four-sleve") {
          x['value'] = 'calf-3/4';
          this.calfLengthThreeFour = x;
          this.longDressPricing.push(this.calfLengthThreeFour)
        }
        if (x.slug === "calf-length-full-sleve") {
          x['value'] = 'calf-full';
          this.calfLengthFull = x;
          this.longDressPricing.push(this.calfLengthFull);
        }
        if (x.slug === "ankle-length-three-four-sleve") {
          x['value'] = 'ankle-3/4';
          this.ankleLengthThreeFour = x;
          this.longDressPricing.push(this.ankleLengthThreeFour);
        }
        if (x.slug === "ankle-length-full-sleve") {
          x['value'] = 'ankle-full';
          this.ankleLengthFull = x;
          this.longDressPricing.push(this.ankleLengthFull);
        }
      });

    });
  }

  getCustomDimen() {
    this.apiService.get(`/vendorportal/masterdata/product-custom-dimension`).subscribe((res: any) => {
      this.customDimensions = res.data;
    });
  }

  sizePricing(ev: any, priceCalculateOn: any) {
    this.fabricPriceValue = Number(ev.value);
    this.priceCalculateOn = priceCalculateOn;
    this.numberOfFabricsGarment.forEach((element: any) => {
      if (element?.checked) {
        // console.log(element);
        this.selectedCYOD = (Number(element?.setting_value) / 100) * this.fabricPriceValue;
        this.cyodSizePrice = {
          value: element.value,
          percentage: element.setting_value + '%',
          amount: (Number(element.setting_value) / 100) * this.fabricPriceValue
        }
      }
    });
    if (this.plusPrice?.checked) {
      this.selectedPlus = (Number(this.plusPrice.setting_value) / 100) * this.fabricPriceValue;
      this.plusSizePrice = {
        value: this.plusSizeValue,
        percentage: this.plusPrice.setting_value + '%',
        amount: (Number(this.plusPrice.setting_value) / 100) * this.fabricPriceValue
      }
    }
    this.longDressPricing.forEach((element: any) => {
      if (element?.checked) {
        // console.log(element);
        this.selectedLongDress = (Number(element.setting_value) / 100) * this.fabricPriceValue;
        this.longDressPrice = {
          value: element.value,
          percentage: element.setting_value + '%',
          amount: (Number(element.setting_value) / 100) * this.fabricPriceValue
        }
      }
    });


  }

  checkChangePrice() {
    this.productPricing.controls.selectPricing.reset();
  }

  selectCyod(ev: any, fabricType: any) {
    this.selectedCYOD = (Number(fabricType.setting_value) / 100) * this.fabricPriceValue;
    this.cyodSizePrice = {
      value: ev.value,
      percentage: fabricType.setting_value + '%',
      amount: (Number(fabricType.setting_value) / 100) * this.fabricPriceValue
    }
    this.numberOfFabricsGarment.map((element: any) => {
      if (element?.setting_name == fabricType.setting_name) {
        element['checked'] = true;
      } else {
        element['checked'] = false;
      }
    });
  }

  selectLongDress(ev: any, fabricType: any) {
    this.selectedLongDress = (Number(fabricType.setting_value) / 100) * this.fabricPriceValue;
    this.longDressPrice = {
      value: ev.value,
      percentage: fabricType.setting_value + '%',
      amount: (Number(fabricType.setting_value) / 100) * this.fabricPriceValue
    }

    this.longDressPricing.map((element: any) => {
      if (element?.setting_name == fabricType.setting_name) {
        element['checked'] = true;
      } else {
        element['checked'] = false;
      }
    });
  }

  selectPlus(ev: any, plusSize: any) {
    // console.log('ev.checked ', ev.checked);
    if (ev.checked) {
      this.selectedPlus = (Number(plusSize.setting_value) / 100) * this.fabricPriceValue;
      this.plusSizePrice = {
        value: this.plusSizeValue,
        percentage: plusSize.setting_value + '%',
        amount: (Number(plusSize.setting_value) / 100) * this.fabricPriceValue
      }
    } else {
      this.selectedPlus = 0;
      this.plusSizePrice = '';
    }
  }
  onInput(setting_value: any) {
    if (setting_value) {
      this.selectedPlus = (Number(setting_value) / 100) * this.fabricPriceValue;
      this.plusSizePrice = {
        value: this.plusSizeValue,
        percentage: setting_value + '%',
        amount: (Number(setting_value) / 100) * this.fabricPriceValue
      }
    } else {
      this.selectedPlus = 0;
      this.plusSizePrice = '';
    }
  }

  selectPlusSize(ev: any) {
    this.plusSizeValue = ev.value;
  }

  getDetails() {
    this.apiService.post(`/vendorportal/product/detail`, { productId: this.productId }).subscribe((resp: any) => {
      this.productDetail = resp.data;
      console.log('productDetail', this.productDetail);
      this.isOrientation = resp.data.fabricOrientation;
      const data = { product_type: resp.data.productTypes._id, product_sub_type: resp.data.SubproductTypes._id };
      this.getProductCategory(data);
      this.lengthType = this.productDetail.productUnit;
      setTimeout(() => {
        this.patchValues();
      }, 2000);
    });
  }

  getFebric() {
    this.apiService.get(`/vendorportal/masterdata/categoriesbytype/fabric`).subscribe((res: any) => {
      this.fabricList = res.data;
    });
  }

  getFabricCategory() {
    this.apiService.post(`/vendorportal/masterdata/category-for-fabric`, {}).subscribe((res: any) => {
      this.fabricTypeCategoryList = res.data;

    });
  }

  checkFabric(event: any, index: number) {
    this.apiService.get(`/vendorportal/masterdata/categories/${event.value}`).subscribe((res: any) => {
      this.fabricCategory[index] = res.data;
    });
  }

  getAttributes(val: any) {
    this.apiService.get(`/vendorportal/masterdata/attributes/${val}`).subscribe((res: any) => {
      this.attributes = res.data;
    });
  }

  // addMaterials() {
  //   this.materials = this.fabricRequired.get('materials') as FormArray;
  //   this.materials.push(this.createItem());
  //   this.addMaterialsReview();
  // }

  // deleteMaterials(index: number) {
  //   this.materials.removeAt(index);
  // }

  // addMaterialsReview() {
  //   this.materialsR = this.reviewForm.get('materialsR') as FormArray;
  //   this.materialsR.push(this.createItemReview());
  // }

  createItem(): FormGroup {
    return this.fb.group({
      materialCategory: ['', [Validators.required]],
      materialSubCategory: ['', [Validators.required]],
      materialUnit: ['', [Validators.required]],
      // availableUnitVariations: ['', [Validators.required]],
      price_per_unit: ['', [Validators.required]],
      material_required: ['', [Validators.required]]
    });
  }

  createItemReview(): FormGroup {
    return this.fb.group({
      materialCategory: [{ value: '', disabled: true }],
      materialSubCategory: [{ value: '', disabled: true }],
      materialUnit: [{ value: '', disabled: true }],
      // availableUnitVariations: [{ value: '', disabled: true }],
      price_per_unit: [{ value: '', disabled: true }],
      material_required: [{ value: '', disabled: true }],
    });
  }

  removeSelectedFile(index: any) {
    this.files.map((fl: any) => {
      if (fl.file) {
        this.files.splice(index, 1);
      } else {
        this.productDetail.imageGallery.splice(index, 1);
        this.files.splice(index, 1);
      }
    });
  }

  setIndex(event: any) {
    this.selectedIndex = event.selectedIndex;
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkProductType(event: any) {
    // this.productTypeList.forEach((element: any) => {
    //   if (element._id === event.value) {
    //     this.subProdTypeList = element.subProductTypes;
    //   }
    // });
    // console.log(event);
    let test = this.productTypeList.find((a: any) => a._id == event?.value)
    this.checkProductSubType({ productValue: test?.parentId, subvalue: test?._id });
  }

  checkProductCategory(event: any) {
    this.productCategoryList.forEach((element: any) => {
      if (element._id === event.value) {
        this.subCategoryList = element.subCategories;
      }
    });
    this.apiService.get(`/vendorportal/masterdata/attributes/${event.value}`).subscribe((res: any) => {
      this.attributes = res.data;
    });
  }

  // @ViewChild('mySelect') mySelect: MatSelect;
  // mySelect

  checkFabricCategory(event: any) {
    // console.log(event);
    this.fabricTypeCategory = event.value;
    this.fabricTypeCategoryList.forEach((element: any) => {
      if (element._id === event.value) {
        this.fabricTypeSubCategoryList = element.subCategories;
      }
    });
    // console.log('category-for-fabric', this.fabricTypeCategoryList);
    // console.log('this.fabricTypeSubCategoryList', this.fabricTypeSubCategoryList);

  }

  checkFabricSubCategory(event: any) {
    // this.fabricTypeSubCategory
    this.fabricTypeSubCategory = event.value;

  }

  checkSubProductCategory(event: any) {
    this.subCategoryList.forEach((element: any) => {
      if (element._id === event.value) {
        if (element.attributeId && element.attributeId.length > 0) {
          this.getAttributes(element._id);
        } else {
          this.getAttributes(element.parentId);
        }
      }
    });
  }

  checkAttributeType(val: any) {
    let checkTitle: any;
    this.attributes.map((at: any) => {
      if (at._id === val) {
        checkTitle = at.title;
      }
    });
    return checkTitle;
  }

  checkAttributeField(val: any) {
    let checkField: any;
    this.attributes.map((at: any) => {
      if (at._id === val) {
        checkField = at.type_val;
      }
    });
    return checkField;
  }

  checkSubAttributeType(val: any, index: number) {
    let titleName: any = [];
    titleName = val.split(',')[index];
    return titleName;
  }

  attributeOption(event: any) {
    const dummAttr: any = [];
    event.value.map((ev: any) => {
      const addAttribute: any = [{ title: '', parentId: ev }];
      this.apiService.get(`/vendorportal/masterdata/attributevalues/${ev}`).subscribe((res: any) => {
        dummAttr.push({ id: ev, data: res.data, selectedAttr: [], selectedAttrName: [], addAttribute });
        setTimeout(() => {
          dummAttr.map((avd: any) => {
            avd.data.map((dt: any) => {
              this.productDetail.productVariantPrices.map((x: any) => {
                x.attributeValues.map((av: any) => { if (dt._id === av._id) { dt.checked = true; } });
              });
            });
          });
          this.attributeValueData = dummAttr;
          this.arrayFinalResult = [];
        }, 500);
      });
    });
  }

  filterOption(event: any) {
    const dummFilter: any = [];
    event.value.map((ev: any) => {
      const addAttribute: any = [{ title: '', parentId: ev }];
      this.apiService.get(`/vendorportal/masterdata/attributevalues/${ev}`).subscribe((res: any) => {
        dummFilter.push({ id: ev, data: res.data, selectedAttr: [], selectedAttrName: [], addAttribute });
        setTimeout(() => {
          dummFilter.map((avd: any) => {
            avd.data.map((dt: any) => {
              this.productDetail.productAttributeFilters.map((x: any) => {
                x.attributeValues.map((av: any) => { if (dt._id === av._id) { dt.checked = true; } });
              });
            });
          });
          this.filterValueData = dummFilter;
          this.arrayFinalResult = [];
        }, 500);
      });
    });
  }

  saveField(id: any, title: any) {
    if (title) {
      const data = { attributeId: id, vendorId: this.storage.getUser().vendorId, title };
      this.apiService.post(`/vendorportal/product/attribute-value-store`, data).subscribe((res: any) => {
        if (res.status === 200) {
          this.attributeValueData.map((x: any) => {
            if (x.id === id) {
              res.data.checked = true;
              x.data.push(res.data);
              x.addAttribute[0].title = '';
              // console.log(this.attributeValueData)
              this.selectAttrVal({ checked: true, source: { value: res.data._id, id: res.data.title } }, res.data.attributeId);
              this.isShowVariantTable = true;
            }
          });
          this.apiService.alert(res.message, 'success');
        } else {
          this.apiService.alert(res.message, 'warning');
        }
      }, err => { this.apiService.alert(err.message, 'error'); });
    } else { this.apiService.alert('Field cannot be empty', 'error'); }
  }

  chunkArray(myArray: any, chunkSize: number) {
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray = [];
    let myChunk: any;
    for (index = 0; index < arrayLength; index += chunkSize) {
      myChunk = myArray.slice(index, index + chunkSize);
      tempArray.push(myChunk);
    }
    return tempArray;
  }

  checkImageSize(fileInput: any) {
    const URL = window.URL || window.webkitURL;
    const Img = new Image();
    const filesToUpload = (fileInput.target.files);
    Img.src = URL.createObjectURL(filesToUpload[0]);
    Img.onload = (e: any) => {
      const height = e.path[0].height;
      const width = e.path[0].width;
      const size = e.path[0].size;
    };
  }

  getProductCategory(data: any) {
    this.apiService.post(`/vendorportal/masterdata/category-by-producttype`, data).subscribe((res: any) => {
      this.productCategoryList = res.data;
    });
  }

  showVariantTable() {
    this.isShowVariantTable = !this.isShowVariantTable;
  }

  checkKeyEvent(ev: any, id: any, title: any) {
    if (ev.key === 'Enter') { this.saveField(id, title); }
  }

  fabricProvider(ev: any) {
    if (this.addFabrics.controls.fabricProvide.value === 'myself') {
      this.apiService.get(`/vendorportal/product/fabric-by-vendor`).subscribe((res: any) => {
        console.log('myself', res.data);
        this.fabricVendorList = res.data;
        this.vendorFabricSelection = [];
        this.imageForCYOD = [];
        this.addFabric();
      });
    } else {
      console.log(this.productCategory);

      this.apiService.post(`/vendorportal/masterdata/fabric-provider`, { subCategory: this.productCategory.controls.fabricTypeSubCategory.value }).subscribe((res: any) => {
        console.log('else', res.data);
        this.allFabricProvider = res.data;
        this.imageForCYOD = [];
        // let names: any = [];
        //   this.productDetail.garmentTypes.map((x: any) => {
        //     names.push({ fRimage: '', imagePreview: this.imageUrl + '/product/' + x.image, name: x.name, color: x.color, price: x.price, quantity: x.quantity, _id: x._id, visible: x.visible, freezeFabric: x.freezeFabric, materialCategory: x.materialCategory, materialSubCategory: x.materialSubCategory, materialPattern: x.materialPattern, fabricLength: x.fabricLength, fabricWidth: x.fabricWidth, imageOpacity: x.imageOpacity, garmentTypeId: [] })
        //   });
        //   let letUiqNames: any = _.uniq(names, 'name')
        //   this.productDetail.garmentTypes.map((x: any) => {
        //     letUiqNames.map((y: any, i: number) => {
        //       if (x.name === y.name) {
        //         // console.log(x);          
        //         y.garmentTypeId.push(x.garmentTypeId)
        //       }
        //     })
        //   })
        //   this.imageForCYOD = letUiqNames;
        //   console.log(this.imageForCYOD);

      });
    }
  }

  checkFabricSupplier(event: any) {
    this.apiService.post(`/vendorportal/masterdata/fabric-provider`, { subCategory: event.value }).subscribe((res: any) => {
      if (res.status === 200) {
        this.allFabricProvider = res.data;
        // this.imageForCYOD = [];
      }
    });
  }

  addFabric() {
    this.imageForCYOD.map((x: any, i: number) => {
      if (x.name === '') {
        this.apiService.alert('Name field is required', 'error');
        this.imageForCYOD.splice(i, 1);
      }
    })
    this.imageForCYOD.push({ fRimage: '', imagePreview: '', garmentTypeId: '', freezeFabric: false, color: '', materialCategory: '', materialSubCategory: '', materialPattern: '', name: '', fabricLength: '', price: '', fabricWidth: '', quantity: 1, imageOpacity: 1 });
    let objDiv = document.getElementsByClassName("addPadding")[0];
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  switchDimension(val: string) {
    this.dimnSwitch = val;
  }

  checkSvgObject(ev: any, item: any) {
    if (ev.checked) {
      let elem: any;
      elem = document.getElementById(ev.source.value);
      elem.style.opacity = "1";
      this.selectedCustomDimensions.push(item._id)
    } else {
      let elem: any;
      elem = document.getElementById(ev.source.value);
      elem.style.opacity = "0";
      this.selectedCustomDimensions.map((x: any, index: number) => {
        if (x === item._id) {
          this.selectedCustomDimensions.splice(index, 1)
        }
      })
    }
  }

  selectPrior() {
    if (this.addFabrics.controls.fabricProvide.value === 'myself') {
      this.apiService.get(`/vendorportal/product/fabric-by-vendor`).subscribe((res: any) => {
        this.fabricVendorList = res.data;
        let modalBox: any = document.getElementsByClassName('modalContainer')[0];
        modalBox.style.display = 'block';
        this.vendorFabricSelection = [];
      });
    } else {
      this.apiService.post(`/vendorportal/masterdata/fabric-provider`, { subCategory: this.fabricTypeSubCategory }).subscribe((res: any) => {
        this.allFabricProvider = res.data;
        this.productDetail.shopdropSupplier.map((x: any) => { this.allFabricProvider.map((y: any) => { if (x === y._id) { y.checked = true; } }) })
        let modalBox: any = document.getElementsByClassName('modalContainer')[0];
        modalBox.style.display = 'block';

      });
    }
  }

  okayPrior() {
    if (this.addFabrics.controls.fabricProvide.value === 'myself') {
      this.imageForCYOD = []
      this.vendorFabricSelection.map((x: any) => {
        this.imageForCYOD.push({
          fRimage: '',
          imagePreview: this.imageUrl + '/product/' + x.image,
          garmentTypeId: x.garmentTypeId,
          freezeFabric: x.freezeFabric,
          name: x.name,
          color: x.color,
          materialCategory: x.materialCategory,
          materialSubCategory: x.materialSubCategory,
          materialPattern: x.materialPattern,
          fabricLength: x.fabricLength,
          price: x.price,
          fabricWidth: x.fabricWidth,
          quantity: x.quantity,
          imageOpacity: x.imageOpacity
        });
      })
    }
    let modalBox: any = document.getElementsByClassName('modalContainer')[0];
    modalBox.style.display = 'none';
  }

  cancelPrior() {
    let modalBox: any = document.getElementsByClassName('modalContainer')[0];
    modalBox.style.display = 'none';
  }

  changeStatus(ev: any, item: any) {
    this.apiService.get(`/vendorportal/product/fabric-status/${this.productId}/${item._id}`).subscribe((res: any) => {
      this.apiService.alert(res.message, 'success');
    });
  }

  addSupplierFabric(ev: any, item: any, i: number) {
    if (ev.target.checked) {
      this.vendorFabricSelection.push(item)
    } else {
      this.vendorFabricSelection.splice(i, 1)
    }
  }

  selectSupplierFabric(ev: any, item: any, i: number) {
    if (ev.checked) {
      this.vendorFabricSelection.push(ev.source.value)
    } else {
      this.vendorFabricSelection.splice(i, 1)
    }
  }

  patchValues() {
    // console.log(this.productDetail);
    // productInfo
    this.productInfo.controls.productName.patchValue(this.productDetail.title);
    this.productInfo.controls.productDescription.patchValue(this.productDetail.description);

    // imageForm
    this.files = [];
    this.imageSrc = this.imageUrl + '/product/' + this.productDetail.image;
    this.productDetail.imageGallery.map((ig: any) => {
      this.files.push({ preview: this.imageUrl + '/product/' + ig.image, file: '' });
    });

    // productCategory
    const data = {
      parentId: this.productDetail.productTypes?._id,
      title: this.productDetail.SubproductTypes?.title,
      _id: this.productDetail.SubproductTypes._id
    }
    this.productCategory.controls.productType.patchValue(this.productDetail.SubproductTypes?._id);

    this.checkProductType({ value: this.productDetail.productTypes._id });
    this.productCategory.controls.productSubType.patchValue(this.productDetail.SubproductTypes._id);
    this.checkProductSubType({ productValue: this.productDetail.productTypes._id, subvalue: this.productDetail.SubproductTypes._id });
    // this.productCategory.controls.productDimensionType.patchValue(this.productDetail.productDimensionType);
    this.productCategory.controls.category.patchValue(this.productDetail.category._id);
    this.productCategory.controls.subCategory.patchValue(this.productDetail.subCategory._id);
    this.checkProductCategory({ value: this.productDetail.category._id });
    this.fabricTypeCategory = this.productDetail.fabricTypeCategory;
    // console.log(this.productDetail);

    this.checkFabricCategory({ value: this.productDetail.fabricTypeCategory });
    this.fabricTypeSubCategory = this.productDetail.fabricTypeSubCategory;


    // productAttributes
    this.productAttributes.controls.inventory.patchValue(this.productDetail.inventoryBased);
    this.inventoryOptions({ value: this.productDetail.inventoryBased });
    this.productAttributes.controls.pricingOptions.patchValue(this.productDetail.priceBased);
    this.pricingOptionsCheck({ value: this.productDetail.priceBased });
    this.productAttributes.controls.totalQuantity.patchValue(this.productDetail.quantity);
    this.productAttributes.controls.sku.patchValue(this.productDetail.sku);
    this.productAttributes.controls.fabricLengthUnit.patchValue(this.productDetail.fabricLengthUnit);
    this.productAttributes.controls.fabricWidthUnit.patchValue(this.productDetail.fabricWidthUnit);
    this.productAttributes.controls.fabricWidth.patchValue(this.productDetail.fabricWidth);
    this.productAttributes.controls.primaryColor.patchValue(this.productDetail.primaryColor);

    const attrArr: any = [];
    const uniqArr: any = [];
    let formatArr: any = [];
    this.productDetail.productVariantPrices[0].attributeDetails.map((av: any) => { attrArr.push(av._id); });
    this.productAttributes.controls.attributes.patchValue(attrArr);

    this.attributeOption({ value: attrArr });
    this.productDetail.productVariantPrices.map((x: any) => {
      x.attributeValues.map((av: any) => {
        uniqArr.push({ checked: true, value: av._id, sourceId: av.title, id: av.attributeId });
        formatArr = _.uniq(uniqArr, 'value');
      });
    });
    setTimeout(() => {
      formatArr.map((x: any) => { this.selectAttrVal({ checked: x.checked, source: { value: x.value, id: x.sourceId } }, x.id); });
    }, 4000);
    this.isShowVariantTable = true;

    // fabric attributes
    const fabricArr: any = [];
    this.productDetail.productAttributeFilters.map((av: any) => { fabricArr.push(av.attributeId); });
    this.filtersAttributes.controls.filters.patchValue(fabricArr);
    this.filterOption({ value: fabricArr })


    // add Fabrics
    this.addFabrics.controls.fabricProvide.patchValue(this.productDetail.fabricProvider);
    this.addFabrics.controls.fabricLengthUnit.patchValue(this.productDetail.fabricLengthUnit);
    this.addFabrics.controls.fabricWidthUnit.patchValue(this.productDetail.fabricWidthUnit);
    this.vendorFabricSelection = this.productDetail.shopdropSupplier;
    let names: any = [];
    this.productDetail.garmentTypes.map((x: any) => {
      names.push({ fRimage: '', imagePreview: this.imageUrl + '/product/' + x.image, name: x.name, color: x.color, price: x.price, quantity: x.quantity, _id: x._id, visible: x.visible, freezeFabric: x.freezeFabric, materialCategory: x.materialCategory, materialSubCategory: x.materialSubCategory, materialPattern: x.materialPattern, fabricLength: x.fabricLength, fabricWidth: x.fabricWidth, imageOpacity: x.imageOpacity, garmentTypeId: [], id: [] })
    });
    let letUiqNames: any = _.uniq(names, 'name')
    this.productDetail.garmentTypes.map((x: any) => {
      letUiqNames.map((y: any, i: number) => {
        if (x.name === y.name) {
          y.garmentTypeId.push(x.garmentTypeId);
          y.id.push(x._id);
        }
      })
    })
    letUiqNames.map((x:any)=>{
      let fabricLength : any = 0;
      if(x.garmentTypeId.length > 1){
        this.productDetail.garmentTypes.map((y: any) => {
          x.id.map((z:any)=>{
            if(z == y._id){
              fabricLength += parseInt(y.fabricLength)
            }
          })
        });
        x['fabricLength'] = fabricLength.toString();
      }
    })
    this.imageForCYOD = letUiqNames;
    this.allCyodData = this.imageForCYOD;
    console.log(this.allCyodData);

    setTimeout(() => { this.loadCanvas(this.allCyodData, this.productDetail) }, 1000);
    this.lengthSleeve = this.productDetail.dressSleeveLengthVariation;
    // productPricing
    this.productPricing.controls.productPrice.patchValue(this.productDetail.price);
    this.productPricing.controls.priceWithoutFabric.patchValue(this.productDetail.priceWithoutMaterial);
    this.productPricing.controls.salePrice.patchValue(this.productDetail.offerPrice);
    this.productPricing.controls.salePriceWithoutFabric.patchValue(this.productDetail.offerPriceWithoutMaterial);
    // this.productPricing.controls.shipToDesignerCost.patchValue(this.productDetail.shipToDesignerCost);
    this.productPricing.controls.selectPricing.patchValue(this.productDetail.choosePrice)
    this.fabricPriceValue = this.productDetail.choosePrice;

    if (this.productDetail.plusSizePrice && this.productDetail.plusSizePrice.value !== '') {
      this.plusPrice.checked = true;
      this.selectedCYOD = Number(this.productDetail.plusSizePrice.amount);

      this.selectedPlus = (Number(this.plusPrice.setting_value) / 100) * this.fabricPriceValue;
      this.plusSizePrice = {
        value: this.plusSizeValue,
        percentage: this.plusPrice.setting_value + '%',
        amount: (Number(this.plusPrice.setting_value) / 100) * this.fabricPriceValue
      }
    }

    if (this.productDetail.ownDesignPrice) {
      this.selectedCYOD = Number(this.productDetail.ownDesignPrice.amount);
      if (this.productDetail.ownDesignPrice.value === "2") { this.twoFabric.checked = true }
      if (this.productDetail.ownDesignPrice.value === "3") { this.threeFabric.checked = true }
      if (this.productDetail.ownDesignPrice.value === "4") { this.fourFabric.checked = true }
      if (this.productDetail.ownDesignPrice.value === "5") { this.fiveFabric.checked = true }
      if (this.productDetail.ownDesignPrice.value === "6") { this.sixFabric.checked = true }
    }

    if (this.productDetail.longDressPrice) {
      this.selectedLongDress = Number(this.productDetail.longDressPrice.amount);
      if (this.productDetail.longDressPrice.value === "calf-3/4") { this.calfLengthThreeFour.checked = true }
      if (this.productDetail.longDressPrice.value === "calf-full") { this.calfLengthFull.checked = true }
      if (this.productDetail.longDressPrice.value === "ankle-3/4") { this.ankleLengthThreeFour.checked = true }
      if (this.productDetail.longDressPrice.value === "ankle-full") { this.ankleLengthFull.checked = true }
    }

    if (this.productDetail.customDimension && this.productDetail.customDimension.length > 0) {
      this.productDetail.customDimension.map((x: any) => {
        this.customDimensions.map((y: any) => {
          if (x._id === y._id) {
            y.checked = true;
            setTimeout(() => {
              this.checkSvgObject({ checked: true, source: { value: x.value } }, { _id: x._id })
            }, 2000);
          }
        })
      })
    }

    if (this.productDetail.customDimensionComment && this.productDetail.customDimensionComment.length > 0) {
      this.productDetail.customDimensionComment.map((x: any, i: number) => {
        this.customDimensions.map((y: any, j: number) => {
          if (i === j) {
            y.comment = x;
          }
        })
      })
    }


    // Review
    this.reviewForm.patchValue(this.productInfo.value);
    this.reviewForm.patchValue(this.productCategory.value);
    this.reviewForm.patchValue(this.productAttributes.value);
    this.reviewForm.patchValue(this.productPricing.value);
    this.reviewForm.controls.fabricProvide.patchValue(this.productDetail.fabricProvider);
  }

  fetchCombinationData() {
    this.productDetail.productVariantPrices.map((pvp: any, index: number) => {
      this.arrayFinalResult[index].variation_image_preview = this.imageUrl + '/product/' + pvp.image;
      this.arrayFinalResult[index].sku = pvp.sku;
      this.arrayFinalResult[index].quantity = pvp.quantity;
      this.arrayFinalResult[index].price = pvp.price;
      this.arrayFinalResult[index].offerPrice = pvp.offerPrice;
      this.arrayFinalResult[index].priceWithoutFabric = pvp.priceWithoutFabric;
      this.arrayFinalResult[index].salePriceWithoutFabric = pvp.salePriceWithoutFabric;
      this.arrayFinalResult[index].shipToDesignerCost = pvp.shipToDesignerCost;
    });
  }

  fabricProviders(ev: any) {
    this.imageForCYOD = [];
    this.imageForCYOD.push({ fRimage: '', imagePreview: '', name: '', color: '', price: 0, quantity: 1, imageOpacity: 1 });
  }

  deleteFabric(item: any, index: number) {
    this.imageForCYOD.splice(index, 1);
  }

  getGarmentTypes() {
    this.apiService.get(`/vendorportal/masterdata/garment-types`).subscribe((res: any) => {
      if (res) {
        this.garmentTypes = res.data;
      }
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageSrc = event.base64;
    this.imageForm.patchValue({
      image: this.base64ToFile(
        event.base64,
        this.imageChangedEvent.target.files[0].name,
      )
    });
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  triggerClick(formValue?:any,formName?:any) {
    if (this.productPricing.valid) {
      this.reviewForm.patchValue(formValue);
    } else {
      this.productPricing.markAllAsTouched();
    }
  }

  toggleEditing() {
    this.isEditing = !this.isEditing;
  }

  addSameValue(ev: any) {
    if (this.isEditing) {
      this.arrayFinalResult.map((x: any, index: number) => {
        if (index > 0) {
          if (ev === 'quantity') { x.quantity = this.arrayFinalResult[0].quantity; }
          if (ev === 'price') { x.price = this.arrayFinalResult[0].price; }
          if (ev === 'offerPrice') { x.offerPrice = this.arrayFinalResult[0].offerPrice; }
          if (ev === 'priceWithoutFabric') { x.priceWithoutFabric = this.arrayFinalResult[0].priceWithoutFabric; }
          if (ev === 'salePriceWithoutFabric') { x.salePriceWithoutFabric = this.arrayFinalResult[0].salePriceWithoutFabric; }
          if (ev === 'sku') { x.sku = this.arrayFinalResult[0].sku; }
          if (ev === 'image') {
            x.variation_image = this.arrayFinalResult[0].variation_image;
            x.variation_image_preview = this.arrayFinalResult[0].variation_image_preview;
          }
          // x.attributes = this.arrayFinalResult[0].attributes;          
        }
      })
    }
  }

  inventoryOptions(event: any) {
    if (event.value === 'product') {
      this.step3VisiblePart = true;
      this.productAttributes.controls.totalQuantity.setValidators([Validators.required]);
      this.productAttributes.controls.pricingOptions.setValidators([Validators.required]);
      this.productPricing.controls.productPrice.setValidators([Validators.required]);
      this.productAttributes.controls.attributes.clearValidators();
      this.productAttributes.controls.attributes.updateValueAndValidity();
      this.productAttributes.controls.pricingOptions.setValue('product');
    } else {
      this.step3VisiblePart = false;
      this.productAttributes.controls.totalQuantity.clearValidators();
      this.productAttributes.controls.pricingOptions.clearValidators();
      this.productAttributes.controls.totalQuantity.updateValueAndValidity();
      this.productAttributes.controls.pricingOptions.updateValueAndValidity();
      this.productAttributes.controls.attributes.setValidators([Validators.required]);
      this.productAttributes.controls.pricingOptions.setValue('variation');
      this.productPricing.controls.productPrice.clearValidators();
      this.productPricing.controls.productPrice.updateValueAndValidity();
    }
  }

  pricingOptionsCheck(event: any) {
    if (event.value === 'variation') {
      this.step3TwoFields = false;
      this.productAttributes.controls.totalQuantity.clearValidators();
      this.productAttributes.controls.totalQuantity.updateValueAndValidity();
    } else {
      this.step3TwoFields = true;
      this.productAttributes.controls.totalQuantity.setValidators([Validators.required]);
    }
  }

  checkProductSubType(event: any) {
    if (event.subvalue === '60a66ac66c3a8b132e9a3aba') {
      this.stepSixAndFiveFields = true;
    } else {
      this.stepSixAndFiveFields = false;
      this.imageForCYOD = [];
    }
    if (event.subvalue === '60a66abc6c3a8b132e9a3ab9') {
      this.stepFabricShowField = true;
      this.productAttributes.controls.primaryColor.setValidators([Validators.required]);
    } else {
      this.stepFabricShowField = false;
      this.productAttributes.controls.primaryColor.clearValidators();
      this.productAttributes.controls.primaryColor.updateValueAndValidity();
      this.productAttributes.controls.primaryColor.reset();
    }
    const data = { product_type: event.productValue, product_sub_type: event.subvalue };
    this.getProductCategory(data);
  }

  formsInit() {
    this.productCategory = this.fb.group({
      productType: ['', [Validators.required]],
      productSubType: ['', [Validators.required]],
      category: ['', [Validators.required]],
      subCategory: ['', [Validators.required]],
      // fabricTypeCategory: [''],
      // fabricTypeSubCategory: [''],
    });
    this.productInfo = this.fb.group({
      productName: ['', [Validators.required]],
      productDescription: ['', [Validators.required]],
    });
    this.productAttributes = this.fb.group({
      inventory: ['', [Validators.required]],
      totalQuantity: [''],
      pricingOptions: [''],
      sku: [''],
      primaryColor: [''],
      attributes: ['', [Validators.required]],
      fabricLengthUnit: ['', [Validators.required]],
      fabricWidthUnit: ['', [Validators.required]],
      fabricWidth: ['', [Validators.required]],
    });
    this.filtersAttributes = this.fb.group({
      filters: [''],
    });
    this.productPricing = this.fb.group({
      productPrice: ['', [Validators.required]],
      priceWithoutFabric: [''],
      salePrice: [''],
      salePriceWithoutFabric: [''],
      selectPricing: [''],
    });
    // this.fabricRequired = this.fb.group({
    //   materials: this.fb.array([])
    // });
    this.fabricOrientation = this.fb.group({});
    this.imageForm = this.fb.group({
      image: ['', [Validators.required]]
    });
    this.addFabrics = this.fb.group({
      fabricProvide: ['', [Validators.required]],
      fabricLengthUnit: [''],
      fabricWidthUnit: ['']
    });
    this.customDimension = this.fb.group({
    });
    this.reviewForm = this.fb.group({
      productType: [{ value: '', disabled: true }],
      productSubType: [{ value: '', disabled: true }],
      category: [{ value: '', disabled: true }],
      subCategory: [{ value: '', disabled: true }],
      fabricTypeCategory: [{ value: '', disabled: true }],
      fabricTypeSubCategory: [{ value: '', disabled: true }],
      inventory: [{ value: '', disabled: true }],
      attributes: [{ value: '', disabled: true }],
      fabricLengthUnit: [{ value: '', disabled: true }],
      fabricWidthUnit: [{ value: '', disabled: true }],
      fabricWidth: [{ value: '', disabled: true }],
      totalQuantity: [{ value: '', disabled: true }],
      pricingOptions: [{ value: '', disabled: true }],
      sku: [{ value: '', disabled: true }],
      primaryColor: [{ value: '', disabled: true }],
      productName: [{ value: '', disabled: true }],
      productDescription: [{ value: '', disabled: true }],
      productPrice: [{ value: '', disabled: true }],
      priceWithoutFabric: [{ value: '', disabled: true }],
      salePrice: [{ value: '', disabled: true }],
      salePriceWithoutFabric: [{ value: '', disabled: true }],
      selectPricing: [{ value: '', disabled: true }],
      fabricProvide: [{ value: '', disabled: true }],
      // materialsR: this.fb.array([])
    });
  }

  deleteItem(item: any, index: number) {
    Swal.fire({
      title: 'Are you sure that you want to delete this value?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Okay'
    }).then((result) => {
      if (result.isConfirmed) {
        const dummArr: any = [];
        const dummArrName: any = [];
        for (let i = 0; i < this.attributeValueData.length; i++) {
          for (let j = 0; j < this.attributeValueData[i].selectedAttr.length; j++) {
            if (this.attributeValueData[i].selectedAttr[j] === item._id) {
              this.attributeValueData[i].selectedAttr.splice(j, 1);
            }
          }
          for (let j = 0; j < this.attributeValueData[i].selectedAttrName.length; j++) {
            if (this.attributeValueData[i].selectedAttrName[j] === item.title) {
              this.attributeValueData[i].selectedAttrName.splice(j, 1);
            }
          }
        }
        this.attributeValueData.map((setArr: any) => {
          dummArr.push(setArr.selectedAttr);
          dummArrName.push(setArr.selectedAttrName);
        });

        const loopOver = (arr: any, str = '', final: any = []) => {
          if (arr.length > 1) {
            arr[0].forEach((v: any) => loopOver(arr.slice(1), str + v + ',', final));
          } else {
            arr[0].forEach((v: any) => final.push(str + v));
          }
          return final;
        };

        const charSet = dummArr;
        const arrResult = loopOver(charSet);
        const attrValue: any = [];
        arrResult.map((ar: any) => {
          this.attributeValueData.map((avd: any, i: number) => {
            if (ar.includes(this.checkSubAttributeType(ar, i))) {
              attrValue.push({ attributeId: avd.id, attributeValues: this.checkSubAttributeType(ar, i) });
            }
          });
        });

        this.finalCombArr = this.chunkArray(attrValue, this.attributeValueData.length);
        const charSetName = dummArrName;
        const arrResultName = loopOver(charSetName);
        this.arrayFinalResult = [];
        arrResultName.map((afr: any) => {
          this.arrayFinalResult.push({ variation_image: '', variation_image_preview: '', sku: '', quantity: '', price: '', offerPrice: '', priceWithoutFabric: '', salePriceWithoutFabric: '', shipToDesignerCost: '', combination: afr, attributes: [] });
        });


        this.apiService.get(`/vendorportal/product/attribute-value/${item._id}`).subscribe((res: any) => {
          this.attributeValueData.map((x: any) => {
            if (x.id === item.attributeId) {
              x.data.splice(index, 1);
            }
          });

          this.apiService.alert(res.message, 'success');
        }, err => { this.apiService.alert(err.message, 'error'); });
      }
    });
  }

  checkIfAttr() {
    const attrArr: any = [];
    const uniqArr: any = [];
    let formatArr: any = [];
    this.productDetail.productVariantPrices[0].attributeDetails.map((av: any) => { attrArr.push(av._id); });
    this.productAttributes.controls.attributes.patchValue(attrArr);
    this.attributeOption({ value: attrArr });
    this.productDetail.productVariantPrices.map((x: any) => {
      x.attributeValues.map((av: any) => {
        uniqArr.push({ checked: true, value: av._id, sourceId: av.title, id: av.attributeId });
        formatArr = _.uniq(uniqArr, 'value');
      });
    });
    setTimeout(() => {
      formatArr.map((x: any) => { this.selectAttrVal({ checked: x.checked, source: { value: x.value, id: x.sourceId } }, x.id); });
    }, 100);
    this.isShowVariantTable = true;
  }

  selectAttrVal(event: any, item: any) {

    const dummArr: any = [];
    const dummArrName: any = [];
    if (event.checked) {
      this.attributeValueData.map((cat: any) => {
        if (cat.id === item) {
          cat.selectedAttr.push(event.source.value);
          cat.selectedAttrName.push(event.source.id);
        }
      });
    } else {
      if (this.attributeValueData.length > 0) {
        for (let i = 0; i < this.attributeValueData.length; i++) {
          for (let j = 0; j < this.attributeValueData[i].selectedAttr.length; j++) {
            if (this.attributeValueData[i].selectedAttr[j] === event.source.value) {
              this.attributeValueData[i].selectedAttr.splice(j, 1);
            }
          }
          for (let j = 0; j < this.attributeValueData[i].selectedAttrName.length; j++) {
            if (this.attributeValueData[i].selectedAttrName[j] === event.source.id) {
              this.attributeValueData[i].selectedAttrName.splice(j, 1);
            }
          }
        }
      }
    }

    this.attributeValueData.map((setArr: any) => {
      dummArr.push(setArr.selectedAttr);
      dummArrName.push(setArr.selectedAttrName);
    });
    const loopOver = (arr: any, str = '', final: any = []) => {
      if (arr.length > 1) {
        arr[0].forEach((v: any) => loopOver(arr.slice(1), str + v + ',', final));
      } else {
        arr[0].forEach((v: any) => final.push(str + v));
      }
      return final;
    };


    const charSet = dummArr;
    const charSetName = dummArrName;
    const arrResult = loopOver(charSet);
    const arrResultName = loopOver(charSetName);

    const attrValue: any = [];

    arrResult.map((ar: any) => {
      this.attributeValueData.map((avd: any, i: number) => {
        if (ar.includes(this.checkSubAttributeType(ar, i))) {
          attrValue.push({ attributeId: avd.id, attributeValues: this.checkSubAttributeType(ar, i) });
        }
      });
    });

    this.finalCombArr = this.chunkArray(attrValue, this.attributeValueData.length);

    this.arrayFinalResult = [];
    arrResultName.map((afr: any) => {
      this.arrayFinalResult.push({ sku: '', quantity: '', price: '', offerPrice: '', combination: afr, attributes: [] });
    });

    setTimeout(() => { this.fetchCombinationData(); }, 500);
  }

  selectFilterVal(event: any, item: any) {
    if (event.checked) {
      this.filterValueData.map((cat: any) => {
        if (cat.id === item) {
          cat.selectedAttr.push(event.source.value);
          cat.selectedAttrName.push(event.source.id.split('1').join(''));
        }
      });
    } else {
      if (this.filterValueData.length > 0) {
        for (let i = 0; i < this.filterValueData.length; i++) {
          for (let j = 0; j < this.filterValueData[i].selectedAttr.length; j++) {
            if (this.filterValueData[i].selectedAttr[j] === event.source.value) {
              this.filterValueData[i].selectedAttr.splice(j, 1);
            }
          }
          for (let j = 0; j < this.filterValueData[i].selectedAttrName.length; j++) {
            if (this.filterValueData[i].selectedAttrName[j] === event.source.id.split('1').join('')) {
              this.filterValueData[i].selectedAttrName.splice(j, 1);
            }
          }
        }
      }
    }
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].type !== 'image/svg+xml') {
        if (event.target.files[0].type.split('/')[0] === 'image') {
          const URL = window.URL || window.webkitURL;
          const Img = new Image();
          const filesToUpload = (event.target.files);
          Img.src = URL.createObjectURL(filesToUpload[0]);
          Img.onload = (e: any) => {
            const [file] = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.imageSrc = reader.result as string;
              this.imageChangedEvent = event;
            };
          };
        } else { this.apiService.alert('Invalid File Format', 'error'); }
      } else { this.apiService.alert('Invalid File Format', 'error'); }
    }
  }

  handleFileInput(event: any) {
    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        if (event.target.files[i].type !== 'image/svg+xml') {
          const URL = window.URL || window.webkitURL;
          const Img = new Image();

          const filesToUpload = (event.target.files);
          Img.src = URL.createObjectURL(filesToUpload[i]);

          Img.onload = (e: any) => {
            const height = e.path[0].height;
            const width = e.path[0].width;

            const reader = new FileReader();
            reader.onload = (ev: any) => {
              if (ev.target.result.split('/')[0].split(':')[1] === 'image') {
                this.files.push({
                  preview: ev.target.result,
                  file: event.target.files[i]
                });
              } else {
                this.apiService.alert('Invalid File Format', 'error');
              }
            };
            reader.readAsDataURL(event.target.files[i]);


          };

        } else {
          this.apiService.alert('Invalid File Format', 'error');
        }

      }
    }
  }

  variantImage(event: any, i: number) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].type !== 'image/svg+xml') {
        if (event.target.files[0].type.split('/')[0] === 'image') {
          const URL = window.URL || window.webkitURL;
          const Img = new Image();
          const filesToUpload = (event.target.files);
          Img.src = URL.createObjectURL(filesToUpload[0]);
          Img.onload = (e: any) => {
            const height = e.path[0].height;
            const width = e.path[0].width;

            const [file] = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.arrayFinalResult[i].variation_image_preview = reader.result as string;
              this.arrayFinalResult[i].variation_image = event.target.files[0];
              this.addSameValue('image')
            };

          };
        } else { this.apiService.alert('Invalid File Format', 'error'); }
      } else { this.apiService.alert('Invalid File Format', 'error'); }
    }
  }

  fabricImage(event: any, i: number) {
    console.log(event.target.files);
    if (event.target.files.length > 0 && event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/webp' || event.target.files[0].type === 'image/jpg') {
      const image = event.target.files[0];
      const formData = new FormData();
      formData.append(`productId`, this.productId);
      if (event.target.files[0]) {
        formData.append(`image`, event.target.files[0]);
      }

      this.apiService.postMultiData(`/vendorportal/product_fabric/add`, formData).subscribe((res: any) => {
        console.log(res);
        if (res.status === 200) {
          res?.data?.image;
          console.log(res?.data?.image);

          this.imageForCYOD[i].imagePreview = 'https://shopdropcdn.dedicatedresource.net/product/' + res?.data?.image;
          this.imageForCYOD[i].fRimage = '';
        }
      });

      // this.imageCompress.uploadFile().then(({ image, orientation }) => {
      //   this.imageCompress.compressFile(image, orientation).then(
      //     result => {
      //       this.imgResultAfterCompress = result;
      //       // this.imageForCYOD[i].imagePreview = this.imgResultAfterCompress;
      //       // this.imageForCYOD[i].fRimage = this.dataURLtoFile(result, 'image.jpeg');
      //       this.imageForCYOD[i].imagePreview = this.convertImage(event.target.files[i]);
      //       this.imageForCYOD[i].fRimage = '';
      //       // console.log('image file',this.dataURLtoFile(result, 'image.jpeg'));
      //     }
      //   );

      // });
    }
  }

  // convertImage(data: any): any {
  //   let image: any;
  //   const formData = new FormData();
  //   formData.append(`productId`, this.productId);
  //   if (data) {
  //     formData.append(`image`, data);
  //   }

  //   this.apiService.postMultiData(`/vendorportal/product_fabric/add`, formData).subscribe((res: any) => {
  //     console.log(res);
  //     if (res.status === 200) {
  //       image = res?.data?.image;
  //       console.log(res?.data?.image);

  //       this.apiService.alert(res.message, 'success');
  //     }
  //   });
  //   console.log('image ==>> ',image);

  //   return 'https://d2fnmg1h87nxsl.cloudfront.net/product/' + image;
  // }

  checkOrientation(val: any) {
    this.isOrientation = val;
  }

  submit(formValue: any, formName: string) {

    if (formName === 'productCategory') {
      if (this.productCategory.valid) {
        this.reviewForm.patchValue(formValue);
      } else {
        this.productCategory.markAllAsTouched();
      }
    }
    if (formName === 'productInfo') {
      if (this.productInfo.valid) {

        this.reviewForm.patchValue(formValue);
      } else {
        this.productInfo.markAllAsTouched();
      }
    }
    if (formName === 'productAttributes') {
      if (this.productAttributes.valid) {
        this.reviewForm.patchValue(formValue);
      } else {
        this.productAttributes.markAllAsTouched();
      }
    }
    if (formName === 'filtersAttributes') {
      let filterAttrVal: any = [];
      this.filterValueData.map((x: any) => {
        filterAttrVal.push({ attributeId: x.id, attributeValues: x.selectedAttr })
      })
      this.finalFilterValueData = filterAttrVal;
    }
    if (formName === 'productPricing') {
      if (this.productPricing.valid) {

        this.reviewForm.patchValue(formValue);
      } else {
        this.productPricing.markAllAsTouched();
      }
    }

    if (formName === 'imageForm') {
      if (this.imageForm.valid) {
      } else {
        this.imageForm.markAllAsTouched();
      }
    }

    if (formName === 'addFabrics') {
      if (this.addFabrics.valid) {
        this.reviewForm.patchValue(formValue);
      } else {
        this.addFabrics.markAllAsTouched();
      }
    }
    if (formName === 'customDimension') {
      if (this.customDimension.valid) {
        let png: any;
        let modelImg: any = document.getElementById('modelImg');
        let svgString = new XMLSerializer().serializeToString(modelImg);
        let canvas: any = document.getElementById("canvas");
        let ctx = canvas.getContext("2d");
        let DOMURL = self.URL || self.webkitURL || self;
        let img = new Image();
        let svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
        let url = DOMURL.createObjectURL(svg);
        img.onload = function () {
          ctx.drawImage(img, 0, 0);
          png = canvas.toDataURL("image/png");
          let pngContainer: any = document.getElementById('png-container');
          pngContainer.innerHTML = '<img src="' + png + '"/>';
          DOMURL.revokeObjectURL(png);
        };
        img.src = url;
        setTimeout(() => {
          let file: any = this.dataURLtoFile(png, 'image.png');
          this.customDimensionImage = file;

          const toBase64 = (file: any) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });

          async function Main() {
            console.log(await toBase64(file));
          }

          Main();
        }, 1000);

      } else {
        this.customDimension.markAllAsTouched();
      }
    }
  }

  dataURLtoFile(dataurl: any, filename: any) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  saveFabric() {
    this.updatedPattern = localStorage.getItem('pattern');
    this.apiService.alert('Garment save successfully', 'success');
  }

  finalSubmit() {
    this.totalprice = (this.fabricPriceValue + this.selectedPlus + this.selectedCYOD + this.selectedLongDress);
    // Product Attributes
    this.arrayFinalResult.map((fa: any, i: number) => {
      this.finalCombArr.map((fc: any, j: number) => {
        if (i === j) {
          fa.attributes = fc;
        }
      });
    });
    let newCreatedCOmbArr1: any = [];
    newCreatedCOmbArr1 = this.arrayFinalResult;
    const variationImage: any = [];
    newCreatedCOmbArr1.map((x: any) => {
      variationImage.push(x.variation_image);
      if (x.variation_image) { x.image = {}; } else { x.image = x.variation_image_preview.split(this.imageUrl + '/product/').join(''); }
    });

    this.variationImages = variationImage;
    this.finalAttributeCombData = _.map(newCreatedCOmbArr1, function (o) { return _.omit(o, 'combination', 'variation_image_preview', 'variation_image'); });

    // Add Fabrics
    let imageForCYODArr: any = [];
    this.imageForCYOD.map((x: any, i: number) => {
      x.garmentTypeId.map((y: any, index: number) => {
        imageForCYODArr.push({
          fRimage: x.fRimage, imagePreview: x.imagePreview, garmentTypeId: y, freezeFabric: x.freezeFabric,
          name: x.name, color: x.color, materialCategory: x.materialCategory, materialSubCategory: x.materialSubCategory, materialPattern: x.materialPattern,
          fabricLength: x.fabricLength, price: x.price, fabricWidth: x.fabricWidth, quantity: x.quantity, imageOpacity: x.imageOpacity
        })
      })
    });
    let newCreatedCOmbArr: any = [];
    newCreatedCOmbArr = imageForCYODArr;
    console.log('imageForCYODArr', imageForCYODArr);

    const fabricImage: any = [];
    newCreatedCOmbArr.map((x: any, index: number) => {
      fabricImage.push(x.fRimage);
      if (x.fRimage) { x.image = '' } else { x.image = x.imagePreview.split(this.imageUrl + '/product/').join('') }
    });

    this.cyodImages = fabricImage;
    this.imageForCYODFinal = _.map(newCreatedCOmbArr, function (o) { return _.omit(o, 'imagePreview', 'fRimage'); });

    console.log(this.imageForCYODFinal);

    // Form Data
    const formData = new FormData();
    if (this.productId) {
      formData.append(`productId`, this.productId);
    }
    let test = this.productTypeList.find((a: any) => a._id == this.reviewForm.value.productType)
    if (this.reviewForm.value.productType) {
      formData.append(`product_type`, test?.parentId);
    }
    if (this.reviewForm.value.productType) {
      formData.append(`product_sub_type`, test?._id);
    }
    if (this.reviewForm.value.productDimensionType) {
      formData.append(`productDimensionType`, JSON.stringify(this.reviewForm.value.productDimensionType));
    }
    if (this.reviewForm.value.category) {
      formData.append(`category`, this.reviewForm.value.category);
    }
    if (this.reviewForm.value.subCategory) {
      formData.append(`subCategory`, this.reviewForm.value.subCategory);
    }
    if (this.reviewForm.value.fabricTypeCategory) {
      formData.append(`fabricTypeCategory`, this.reviewForm.value.fabricTypeCategory);
    }
    if (this.reviewForm.value.fabricTypeSubCategory) {
      formData.append(`fabricTypeSubCategory`, this.reviewForm.value.fabricTypeSubCategory);
    }
    if (this.reviewForm.value.inventory) {
      formData.append(`inventoryBased`, this.reviewForm.value.inventory);
    }
    if (this.reviewForm.value.pricingOptions) {
      formData.append(`priceBased`, this.reviewForm.value.pricingOptions);
    }
    if (this.reviewForm.value.totalQuantity) {
      formData.append(`quantity`, this.reviewForm.value.totalQuantity);
    }
    if (this.reviewForm.value.sku) {
      formData.append(`sku`, this.reviewForm.value.sku);
    }
    if (this.reviewForm.value.productName) {
      formData.append(`title`, this.reviewForm.value.productName);
    }
    if (this.reviewForm.value.productDescription) {
      formData.append(`description`, this.reviewForm.value.productDescription);
    }
    if (this.totalprice) {
      formData.append(`totalPrice`, this.totalprice);
    }
    if (this.reviewForm.value.productPrice) {
      formData.append(`price`, this.reviewForm.value.productPrice);
    }
    if (this.reviewForm.value.priceWithoutFabric) {
      formData.append(`priceWithoutMaterial`, this.reviewForm.value.priceWithoutFabric);
    }
    if (this.reviewForm.value.salePriceWithoutFabric) {
      formData.append(`offerPriceWithoutMaterial`, this.reviewForm.value.salePriceWithoutFabric);
    }
    if (this.reviewForm.value.shipToDesignerCost) {
      formData.append(`shipToDesignerCost`, this.reviewForm.value.shipToDesignerCost);
    }
    if (this.reviewForm.value.salePrice) {
      formData.append(`offerPrice`, this.reviewForm.value.salePrice);
    }
    if (this.imageForm.value.image) {
      formData.append('image', this.imageForm.value.image);
    }
    if (this.files.length && this.files.length > 0) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].file) {
          formData.append(`imageGallery_${i}`, this.files[i].file);
        }
      }
    }
    // if (this.reviewForm.value.materialsR) {
    //   formData.append(`materials`, JSON.stringify(this.reviewForm.value.materialsR));
    // }
    if (this.finalAttributeCombData) {
      formData.append(`attributes`, JSON.stringify(this.finalAttributeCombData));
    }
    if (this.finalFilterValueData) {
      formData.append(`filterAttributes`, JSON.stringify(this.finalFilterValueData));
    }
    if (this.reviewForm.value.fabricLengthUnit) {
      formData.append(`fabricLengthUnit`, this.reviewForm.value.fabricLengthUnit);
    }
    if (this.reviewForm.value.fabricWidthUnit) {
      formData.append(`fabricWidthUnit`, this.reviewForm.value.fabricWidthUnit);
    }
    if (this.reviewForm.value.fabricWidth) {
      formData.append(`fabricWidth`, this.reviewForm.value.fabricWidth);
    }
    if (this.variationImages && this.variationImages.length && this.variationImages.length > 0) {
      for (let i = 0; i < this.variationImages.length; i++) {
        if (this.variationImages[i]) { formData.append(`variationimage_${i}`, this.variationImages[i]); }
      }
    }
    if (this.reviewForm.value.fabricProvide) {
      formData.append(`fabricProvider`, this.reviewForm.value.fabricProvide);
    }
    if (this.imageForCYODFinal) {
      formData.append(`garmentTypes`, JSON.stringify(this.imageForCYODFinal));

    }
    if (this.cyodImages && this.cyodImages.length && this.cyodImages.length > 0) {
      for (let i = 0; i < this.cyodImages.length; i++) {
        if (this.cyodImages[i]) {
          formData.append(`cyodimage_${i}`, this.cyodImages[i]);
        }
      }
    }
    if (this.lengthSleeve) {
      formData.append(`dressSleeveLengthVariation`, JSON.stringify(this.lengthSleeve));
    }
    if (this.plusSizePrice) {
      formData.append(`plusSizePrice`, JSON.stringify(this.plusSizePrice));
    }
    if (this.cyodSizePrice) {
      formData.append(`ownDesignPrice`, JSON.stringify(this.cyodSizePrice));
    }
    if (this.longDressPrice) {
      formData.append(`longDressPrice`, JSON.stringify(this.longDressPrice));
    }
    if (this.priceCalculateOn) {
      formData.append(`priceCalculateOn`, this.priceCalculateOn);
    }
    if (this.fabricPriceValue) {
      formData.append(`choosePrice`, JSON.stringify(this.fabricPriceValue));
    }
    if (this.selectedCustomDimensions) {
      formData.append(`customDimension`, JSON.stringify(this.selectedCustomDimensions));
    }
    if (this.customDimensionsComment && this.customDimensionsComment.length > 0) {
      formData.append(`customDimensionComment`, JSON.stringify(this.customDimensionsComment));
    }
    if (this.customDimensionImage) {
      formData.append(`customDimensionImage`, this.customDimensionImage);
    }
    if (this.vendorFabricSelection && this.vendorFabricSelection.length > 0) {
      formData.append(`shopdropSupplier`, JSON.stringify(this.vendorFabricSelection));
    }
    if (this.updatedPattern && this.updatedPattern.length > 0) {
      formData.append(`patternJson`, this.updatedPattern);
    }
    formData.append(`fabricOrientation`, this.isOrientation);
    let topPosData: any = Number(sessionStorage.getItem('clothTopPos'));
    let leftPosData: any = Number(sessionStorage.getItem('clothLeftPos'));
    formData.append('clothTopPos', topPosData);
    formData.append('clothLeftPos', leftPosData);
    formData.append(`productUnit`, this.lengthType)
    this.apiService.postMultiData(`/vendorportal/product/edit`, formData).subscribe((res: any) => {
      if (res.status === 200) {
        this.apiService.alert(res.message, 'success');
        this.isDirty = false;
        this.router.navigateByUrl('/vendor/product-listing');
      }
    }, err => {
      this.apiService.alert(err.message, 'error');
    });
  }

  switchLength(val: string) {
    this.lengthType = val;
    sessionStorage.setItem("dimensionLengthType", JSON.stringify(this.lengthType));
  }

  editHelp(item: any) {
    // console.log(item);
    const dialogRef = this.dialog.open(EditHelpComponent, { width: '400px', data: item, });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.customDimensionsComment = [];
        this.customDimensions.map((x: any) => {
          if (x._id === result._id) {
            this.customDimensionsComment.push(result.comment);
          } else {
            this.customDimensionsComment.push(x.comment);
          }
        })
        // console.log('customDimensionsComment', this.customDimensionsComment);        
      }
    });
  }

  onBlurMethod(index: number) {
    let imageCheckCYOD: any = [] = _.uniq(this.imageForCYOD, 'name');
    // console.log(imageCheckCYOD, this.imageForCYOD);

    if (this.imageForCYOD.length !== imageCheckCYOD.length) {
      this.apiService.alert('Name field need to be unique', 'error');
      this.imageForCYOD[index].name = '';
    }
  }

  checkFabricLength(event: any) {
    this.addFabrics.controls.fabricLengthUnit.patchValue(event.value);
    this.addFabrics.controls.fabricWidthUnit.patchValue(event.value);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      // console.log(this.imageForCYOD);

    }, 5000);
  }
}
